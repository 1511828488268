import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GridMainMenu from './GridMainMenu';
import TopFiveProduct from './TopFiveProduct';
import BannerSlide from './BannerSlide';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';

function Homepage({ username, namaLengkap }) {
    const [data, setData] = useState({ firstNumber: 0, secondNumber: 0, thirdNumber: 0 });
    const month = new Date().toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const year = new Date().getFullYear();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/data/get-card-djp_summary`, {
            params: { username },
            withCredentials: true
        })
            .then((response) => {
                if (response.data.success) {
                    setData({
                        firstNumber: response.data.data.firstNumber,
                        secondNumber: response.data.data.secondNumber,
                        thirdNumber: response.data.data.thirdNumber,
                    });
                } else {
                    console.error('Failed to fetch data.');
                }
            })
            .catch(() => {
                console.error('Failed to fetch data due to an error.');
            });
    }, [username]);

    return (
        <div className="homepage-container">
            <div className="homepage-content">
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-4 px-4 mb-2">
                        <p className="p-0 m-0" style={{fontSize:'13px'}}>Semangat Pagi, <b>{namaLengkap.toUpperCase()}</b></p> 
                    </div>
                </div>
                <div className="row justify-content-center mt-1" style={{ padding: '0px 10px', fontSize: '9px' }}><BannerSlide /></div>
                <div className="row justify-content-center mt-1" style={{ padding: '0px 10px', fontSize: '9px' }}><GridMainMenu /></div>
                <div className="row justify-content-center mt-1" style={{ padding: '0px 10px', fontSize: '9px' }}>
                    <div className="col-md-12 pt-2 px-3">
                        <div className="p-3 rounded" style={{ background: 'linear-gradient(to right, #8F0B3D, #DC3545)', color: '#DDD' }}>
                            <Container className="text-center" style={{fontSize:'14px'}}>
                                <Row>
                                    <Col>{year}</Col>
                                    <Col>{month}</Col>
                                    <Col>TODAY</Col>
                                </Row>
                                <Row style={{ fontWeight:'bold', fontSize: '20px'}}>
                                    <Col>{data.firstNumber}</Col>
                                    <Col>{data.secondNumber}</Col>
                                    <Col>{data.thirdNumber}</Col>
                                </Row>
                            </Container>
                        </div>
                    </div>            
                </div>
                <div className="row justify-content-center mt-4" style={{ padding: '0px 15px', fontSize: '9px' }}><TopFiveProduct /></div>                
                <br/><br/><br/>
            </div>            
        </div>
    );
}

export default Homepage;
