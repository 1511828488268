import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';

const ProductCatalog = () => {
    const [selectedSpeed, setSelectedSpeed] = useState(null);

    const handleButtonClick = (speed) => {
        setSelectedSpeed(speed);
    };

    const renderProductList = () => {
        switch (selectedSpeed) {
            case '10Mbps':
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>      
                            <ListGroup.Item as="li">10Mbps Paket EZnet</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB A</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB B</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB C</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB D</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit Basic</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit Compete</ListGroup.Item>
                        </ListGroup>
                    </div>
                );
            case '20Mbps':
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>      
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB C</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB D</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB C</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 270GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 270GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Basic</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Fortress</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Super Fortress</ListGroup.Item>
                        </ListGroup>
                    </div>
                );
            case '30Mbps':
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>      
                            <ListGroup.Item as="li">30Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Netflix 3P</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 1P</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 3P</ListGroup.Item>
                        </ListGroup>
                    </div>
                );
            case '50Mbps':
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>      
                            <ListGroup.Item as="li">50Mbps IndiHome Movie 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps JITU 1 Package 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Netflix 3P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 3P </ListGroup.Item>
                        </ListGroup>
                    </div>
                );
            case '100Mbps':
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>  
                            <ListGroup.Item as="li">100Mbps IndiHome Movie 1P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps JITU 1 Package 1P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Gamer 3P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket JITU 1 1P</ListGroup.Item>
                        </ListGroup>
                    </div>
                );
            default:
                return (
                    <div style={{ fontSize: '12px'}}>
                        <ListGroup as="ol" numbered>                              
                            <ListGroup.Item as="li">10Mbps Paket EZnet</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB A</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB B</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB C</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit 120GB D</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit Basic</ListGroup.Item>
                            <ListGroup.Item as="li">10Mbps Paket Orbit Compete</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB C</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 170GB D</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 220GB C</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 270GB A</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit 270GB B</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Basic</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Fortress</ListGroup.Item>
                            <ListGroup.Item as="li">20Mbps Paket Orbit Super Fortress</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket IndiHome Netflix 3P</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 1P</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">30Mbps Paket JITU 1 3P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps IndiHome Movie 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps JITU 1 Package 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket IndiHome Netflix 3P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 1P</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">50Mbps Paket JITU 1 3P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps IndiHome Movie 1P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps JITU 1 Package 1P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket Digital JITU 1</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Gamer 2P-Phone</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Gamer 3P</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket IndiHome Netflix 2P-TV</ListGroup.Item>
                            <ListGroup.Item as="li">100Mbps Paket JITU 1 1P</ListGroup.Item>
                        </ListGroup>
                    </div>
                );
        }
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh' }}>
            <div className="container mt-0 px-0" style={{ backgroundColor: '#FFFFFF', borderRadius: '10px 10px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-4 px-4">
                        <p className="p-0 m-0 fw-bold" style={{ fontSize: '15px' }}>Product Catalog</p>
                    </div>
                    <div className="col-md-12 pt-4 px-2 text-start">
                        <center>
                            <Button className="px-2" variant="outline-primary" style={{ fontSize: '10px' }} onClick={() => handleButtonClick('10Mbps')}>10 Mbps</Button>{' '}
                            <Button className="px-2" variant="outline-secondary" style={{ fontSize: '10px' }} onClick={() => handleButtonClick('20Mbps')}>20 Mbps</Button>{' '}
                            <Button className="px-2" variant="outline-success" style={{ fontSize: '10px' }} onClick={() => handleButtonClick('30Mbps')}>30 Mbps</Button>{' '}
                            <Button className="px-2" variant="outline-warning" style={{ fontSize: '10px' }} onClick={() => handleButtonClick('50Mbps')}>50 Mbps</Button>{' '}
                            <Button className="px-2" variant="outline-danger" style={{ fontSize: '10px' }} onClick={() => handleButtonClick('100Mbps')}>100 Mbps</Button>
                        </center>
                    </div>
                </div>
                <div className="col-md-12 pt-4 px-4">
                    {renderProductList()}
                </div>
            </div>
        </div>
    );
}

export default ProductCatalog;
