import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateInternalUserArea = ({ username, areaflag }) => {
    const [uname, setUname] = useState('');
    const [namaLengkap, setNamaLengkap] = useState('');

    const [regionName, setRegionName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [clusterName, setClusterName] = useState('');
    const [message, setMessage] = useState('');
    const [regionals, setRegionals] = useState([]);    
    const [branchs, setBranchs] = useState([]);
    const [clusters, setClusters] = useState([]);

    useEffect(() => {
        if (areaflag) {
            handleAreaChange(areaflag);
        }
    }, [areaflag]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (regionName === '') {
            setMessage('Regional belum dipilih');
            return;
        }

        axios.post(`${process.env.REACT_APP_SERVER}/submit-user-internal-area`, {
            username,
            uname,
            namaLengkap,
            areaflag,
            regionName,
            branchName,
            clusterName,
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
                setUname('');
                setNamaLengkap('');
                setRegionName('');
                setBranchName('');
                setClusterName('');
            } else {
                setMessage(response.data.message); 
                setUname('');
                setNamaLengkap('');
                setRegionName('');
                setBranchName('');
                setClusterName('');
            }
        }).catch((error) => {
            setMessage('Failed due to an error.');
            setUname('');
            setNamaLengkap('');
            setRegionName('');
            setBranchName('');
            setClusterName('');
        });
    };

    const handleAreaChange = (area) => {
        let regionalsOptions = [];

        switch (area) {
            case 'AREA 1':
                regionalsOptions = ['SUMBAGSEL', 'SUMBAGTENG', 'SUMBAGUT'];
                break;
            case 'AREA 2':
                regionalsOptions = ['CENTRAL JABOTABEK', 'EASTERN JABOTABEK', 'JABAR', 'WESTERN JABOTABEK'];
                break;
            case 'AREA 3':
                regionalsOptions = ['JATENG-DIY', 'JATIM', 'BALI NUSRA'];
                break;
            case 'AREA 4':
                regionalsOptions = ['KALIMANTAN', 'SULAWESI', 'MALUKU DAN PAPUA'];
                break;
            default:
                regionalsOptions = [];
                break;
        }

        setRegionals(regionalsOptions);
    };

    const handleRegionChange = (e) => {
        const region = e.target.value;
        //setTipeUser(area);
        let branchsOptions = [];

        switch (region) {
            case 'SUMBAGSEL': branchsOptions = ['BENGKULU', 'JAMBI', 'LAMPUNG', 'PALEMBANG', 'PANGKAL PINANG']; break;
            case 'SUMBAGTENG': branchsOptions = ['BATAM', 'BUKIT TINGGI', 'DUMAI', 'PADANG', 'PEKANBARU']; break;
            case 'SUMBAGUT': branchsOptions = ['BANDA ACEH', 'BINJAI', 'MEDAN', 'PADANG SIDEMPUAN', 'PEMATANG SIANTAR', 'RANTAU PRAPAT']; break;
            case 'CENTRAL JABOTABEK': branchsOptions = ['NORTHERN JAKARTA', 'SOUTHERN JAKARTA']; break;
            case 'EASTERN JABOTABEK': branchsOptions = ['BOGOR', 'BEKASI', 'KARAWANG']; break;
            case 'JABAR': branchsOptions = ['BANDUNG', 'CIREBON', 'SOREANG','TASIKMALAYA']; break;
            case 'WESTERN JABOTABEK': branchsOptions = ['SERANG', 'TANGERANG']; break;
            case 'JATENG-DIY': branchsOptions = ['MAGELANG', 'PEKALONGAN', 'PURWOKERTO', 'SEMARANG', 'SURAKARTA', 'YOGYAKARTA']; break;
            case 'JATIM': branchsOptions = ['JEMBER', 'LAMONGAN', 'MADIUN', 'MALANG', 'SIDOARJO', 'SURABAYA']; break;
            case 'BALI NUSRA': branchsOptions = ['DENPASAR', 'FLORES', 'KUPANG', 'MATARAM']; break;
            case 'KALIMANTAN': branchsOptions = ['BALIKPAPAN', 'BANJARMASIN', 'PALANGKARAYA', 'PANGKALAN BUN', 'PONTIANAK', 'SAMARINDA', 'TARAKAN']; break;
            case 'SULAWESI': branchsOptions = ['BONE', 'KENDARI', 'MAKASSAR', 'MANADO', 'PALU', 'PARE-PARE', 'TERNATE']; break;
            case 'MALUKU DAN PAPUA': branchsOptions = ['AMBON', 'JAYAPURA', 'SORONG', 'TIMIKA']; break;                                                                                
            default:
                branchsOptions = [];
                break;
        }

        setBranchs(branchsOptions);
        setRegionName(region); // Reset the regional selection when area changes
    };

    const handleBranchChange = (e) => {
        const branch = e.target.value;
        //setTipeUser(area);
        let clustersOptions = [];

        switch (branch) {
            case 'BALIKPAPAN': clustersOptions = ['KUTAI','BALIKPAPAN','KOTA BALIKPAPAN']; break;
            case 'BANJARMASIN': clustersOptions = ['HULU SUNGAI','KOTABARU','BANJAR']; break;
            case 'BATAM': clustersOptions = ['TANJUNG PINANG','BATAM','GREATER BATAM']; break;
            case 'BENGKULU': clustersOptions = ['KOTA BENGKULU','MUSI RAWAS','BENGKULU']; break;
            case 'BOGOR': clustersOptions = ['SUKABUMI','BOGOR']; break;
            case 'BONE': clustersOptions = ['BULUKUMBA','WAJO SOPENG','BONE']; break;
            case 'CIREBON': clustersOptions = ['SUBANG INDRAMAYU','CIREBON RAYA']; break;
            case 'DENPASAR': clustersOptions = ['BALI BARAT','BALI TIMUR','BALI TENGAH']; break;
            case 'JAMBI': clustersOptions = ['KERINCI MERANGIN','MUARO DAN KOTA JAMBI','TEBO BUNGO','TANJUNG JABUNG','SAROLANGUN']; break;
            case 'JAYAPURA': clustersOptions = ['PUNCAK JAYAWIJAYA','KOTA JAYAPURA','BIAK NUMFOR','KEEROM','SENTANI']; break;
            case 'JEMBER': clustersOptions = ['JEMBER','SITUBONDOWOSO','KOTA PROBOLINGGO','BANYUWANGI']; break;
            case 'LAMONGAN': clustersOptions = ['LAMONGAN GRESIK','TUBAN BOJONEGORO']; break;
            case 'MALANG': clustersOptions = ['MALANG','TULUNGAGUNG']; break;
            case 'MATARAM': clustersOptions = ['SUMBAWA TIMUR','SUMBAWA BARAT','LOMBOK']; break;
            case 'MEDAN': clustersOptions = ['KOTA MEDAN','MEDAN','SERDANG']; break;
            case 'PANGKAL PINANG': clustersOptions = ['BANGKA SELATAN','BANGKA UTARA']; break;
            case 'PARE-PARE': clustersOptions = ['MAMUJU MAJENE','LUWU PALOPO','TORAJA','PARE SIDRAP']; break;
            case 'PEKANBARU': clustersOptions = ['INDRAGIRI HILIR','PELALAWAN','SIAK MERANTI','INDRAGIRI HULU','PEKANBARU','GREATER PEKANBARU']; break;
            case 'PURWOKERTO': clustersOptions = ['CILACAP BANYUMAS','WONOSOBO BANJARNEGARA']; break;
            case 'RANTAU PRAPAT': clustersOptions = ['ASAHAN','PADANG LAWAS','LABUHAN BATU']; break;
            case 'SERANG': clustersOptions = ['LEBAK PANDEGLANG','SERANG CILEGON','KAB TANGERANG']; break;
            case 'SIDOARJO': clustersOptions = ['SIDOARJO PASURUAN','JOMBANG MOJOKERTO']; break;
            case 'SOREANG': clustersOptions = ['BANDUNG','SUMEDANG MAJALENGKA']; break;
            case 'SORONG': clustersOptions = ['MANOKWARI','CENDRAWASIH','NABIRE','SORONG RAJA AMPAT']; break;
            case 'SOUTHERN JAKARTA': clustersOptions = ['JAKPUSEL','JAKTIM','JAKTIM 1']; break;
            case 'SURABAYA': clustersOptions = ['MADURA','KOTA SURABAYA']; break;
            case 'TANGERANG': clustersOptions = ['KOTA TANGERANG','TANGSEL']; break;
            case 'TASIKMALAYA': clustersOptions = ['CIAMIS','GARUT','TASIKMALAYA']; break;
            case 'YOGYAKARTA': clustersOptions = ['YOGYAKARTA']; break;
            case 'AMBON': clustersOptions = ['KOTA AMBON','SERAM TIMUR MALUKU','KEPULAUAN TUAL','SERAM BARAT BURU']; break;
            case 'BANDA ACEH': clustersOptions = ['ACEH BARAT','GAYO','SABANG ACEH','PIDIE BIREUN','ACEH SELATAN']; break;
            case 'BANDUNG': clustersOptions = ['CIANJUR','KOTA BANDUNG']; break;
            case 'BEKASI': clustersOptions = ['DEPOK','KOTA BEKASI']; break;
            case 'BINJAI': clustersOptions = ['LANGSA','LANGKAT BINJAI','LHOKSEUMAWE']; break;
            case 'BUKIT TINGGI': clustersOptions = ['AGAM','PAYAKUMBUH','PASAMAN']; break;
            case 'DUMAI': clustersOptions = ['ROKAN HILIR','ROKAN HULU','DUMAI BENGKALIS','KAMPAR','KUANTAN']; break;
            case 'FLORES': clustersOptions = ['MANGGARAI','FLORES TIMUR','ENDE SIKKA']; break;
            case 'KARAWANG': clustersOptions = ['KARAWANG PURWAKARTA','BEKASI']; break;
            case 'KENDARI': clustersOptions = ['WAKATOBI','KENDARI','KOLAKA']; break;
            case 'KUPANG': clustersOptions = ['MALAKA TIMTIM BELU','SUMBA','KUPANG ROTE']; break;
            case 'LAMPUNG': clustersOptions = ['PRINGSEWU LAMPUNG TIMUR','LAMPUNG BARAT','KOTA BANDAR LAMPUNG','LAMPUNG METRO','TULANG BAWANG','WAY KANAN']; break;
            case 'MADIUN': clustersOptions = ['MADIUN','PONOROGO','KEDIRI']; break;
            case 'MAGELANG': clustersOptions = ['MAGELANG TEMANGGUNG','KEBUMEN']; break;
            case 'MAKASSAR': clustersOptions = ['BARRU MAROS','GOWA','KOTA MAKASSAR']; break;
            case 'MANADO': clustersOptions = ['MANADO TALAUD','GORONTALO','BOLAANG MONGONDOW','BITUNG MINAHASA','PAHUWATO']; break;
            case 'NORTHERN JAKARTA': clustersOptions = ['JAKUT','JAKBAR','NEW JAKUT']; break;
            case 'PADANG': clustersOptions = ['KOTA PADANG','SOLOK SAWAH LUNTO','SIJUNJUNG DHARMASRAYA','MENTAWAI DAN PESISIR','PADANG PARIAMAN']; break;
            case 'PADANG SIDEMPUAN': clustersOptions = ['TAPANULI','NIAS','SIDEMPUAN']; break;
            case 'PALANGKARAYA': clustersOptions = ['PALANGKARAYA','BARITO']; break;
            case 'PALEMBANG': clustersOptions = ['BANYUASIN','OGAN KOMERING ILIR','KOTA PALEMBANG','MUSI BANYUASIN','PRABUMULIH','OGAN KOMERING ULU']; break;
            case 'PALU': clustersOptions = ['BANGGAI','MOROWALI','PALU DONGGALA','POSO SIGI','PARIGI TOLI']; break;
            case 'PANGKALAN BUN': clustersOptions = ['KOTAWARINGIN','KETAPANG']; break;
            case 'PEKALONGAN': clustersOptions = ['BATANG','TEGAL BREBES','PEMALANG PURBA']; break;
            case 'PEMATANG SIANTAR': clustersOptions = ['SIMALUNGUN SIANTAR','KARO','SAMOSIR','SERDANG BEDAGAI']; break;
            case 'PONTIANAK': clustersOptions = ['SAMBAS','PONTIANAK','SINTANG']; break;
            case 'SAMARINDA': clustersOptions = ['BONTANG','KOTA SAMARINDA']; break;
            case 'SEMARANG': clustersOptions = ['SEMARANG','DEMAK','JEPARA KUDUS','PATI']; break;
            case 'SURAKARTA': clustersOptions = ['BOYOLALI','SURAKARTA','SRAGEN']; break;
            case 'TARAKAN': clustersOptions = ['BERAU','TARAKAN']; break;
            case 'TERNATE': clustersOptions = ['HALMAHERA MOROTAI','HALMAHERA TIDORE','TERNATE']; break;
            case 'TIMIKA': clustersOptions = ['MIMIKA','MERAUKE']; break;                                                                                                                   
            default:
                clustersOptions = [];
                break;
        }

        setClusters(clustersOptions);
        setBranchName(branch); 
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Create User Internal</p>
                    </div>
                </div>
            </div>
                    
            <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-5 mb-5">                    
                        <form onSubmit={handleSubmit}>                                                         
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-2">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Username</label></p>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={uname}
                                                        onChange={(e) => setUname(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-2">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Nama Lengkap </label></p>
                                                <div>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={namaLengkap} 
                                                        onChange={(e) => setNamaLengkap(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="row justify-content-center mb-3">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Regional</label>
                                                <div>
                                                    <select
                                                        required 
                                                        value={regionName} 
                                                        onChange={handleRegionChange}  
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Select Regional</option>
                                                        {regionals.map((regional, index) => (
                                                            <option key={index} value={regional}>{regional}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Branch</label>
                                                <div>
                                                    <select
                                                        value={branchName}  
                                                        onChange={handleBranchChange}
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Select Branch</option>
                                                        {branchs.map((branch, index) => (
                                                            <option key={index} value={branch}>{branch}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                            
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Cluster</label>
                                                <div>
                                                    <select
                                                        value={clusterName}  
                                                        onChange={(e) => setClusterName(e.target.value)} 
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Select Cluster</option>
                                                        {clusters.map((cluster, index) => (
                                                            <option key={index} value={cluster}>{cluster}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-5">
                                <div className="col-md-12">
                                    <div className="card">
                                        <button type="submit" className="btn btn-danger p-2">Tambah User</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {message && <p className="mt-3 text-center">{message}</p>}
                        <br/><br/><br/><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateInternalUserArea;
