import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { FaStore } from 'react-icons/fa';
import Lokasi from './Lokasi';
import ItemsCarousel from 'react-items-carousel';
import { Modal, Button } from 'react-bootstrap';

axios.defaults.withCredentials = true;

function Dashboard({ username, namaLengkap }) {
    const navigate = useNavigate();
    const [outlets, setOutlets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedOutlet, setSelectedOutlet] = useState(null);
    const [showCheckOutModal, setShowCheckOutModal] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        const showPosition = (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            setLatitude(latitude);
            setLongitude(longitude);
            fetchNearbyOutlets(latitude, longitude);
        };

        const showError = (error) => {
            console.error('Error fetching location:', error);
            setLoading(false);
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            console.error('Geolocation is not supported by this browser.');
            setLoading(false);
        }

        const fetchNearbyOutlets = async (latitude, longitude) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/nearby-outlets`, {
                    params: {
                        lat: latitude,
                        lng: longitude,
                        uname: username
                    }
                });
                setOutlets(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching outlets:', error);
                setLoading(false);
            }
        };

    }, [username]);

    const handleCheckInClick = (outlet, status) => {
        setSelectedOutlet({ ...outlet, status });
        setShowModal(true);
    };

    const handleCheckInConfirm = async () => {
        if (!selectedOutlet) return;

        try {
            // Perform the check-in or close outlet operation
            await axios.post(`${process.env.REACT_APP_SERVER}/data/check-in`, {
                username,
                outlet_id: selectedOutlet.outlet_id,
                status: selectedOutlet.status,
                latitude,
                longitude
            });

            setShowModal(false);

            // Refresh the carousel only if the status is 0 (Close Outlet)
            if (selectedOutlet.status === 0) {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/nearby-outlets`, {
                    params: {
                        lat: latitude,
                        lng: longitude,
                        uname: username
                    }
                });
                setOutlets(response.data);
            } else {
                // For status 1, navigate to the survey page
                navigate(`/survey/${selectedOutlet.outlet_id}`);
            }

        } catch (error) {
            console.error('Error checking in:', error);
            setShowModal(false);
            const errorMessage = error.response?.data?.message || 'Gagal check-in. Silakan coba lagi.';
            alert(errorMessage);
        }
    };

    const handleCheckOutClick = (outlet) => {
        setSelectedOutlet(outlet);
        setShowCheckOutModal(true);
    };

    const handleCheckOutConfirm = async () => {
        if (!selectedOutlet) return;

        try {
            await axios.post(`${process.env.REACT_APP_SERVER}/data/check-out`, {
                username,
                outlet_id: selectedOutlet.outlet_id,
                latitude,
                longitude
            });

            setShowCheckOutModal(false);

            const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/nearby-outlets`, {
                params: {
                    lat: latitude,
                    lng: longitude,
                    uname: username
                }
            });

            setOutlets(response.data);

        } catch (error) {
            console.error('Error checking out:', error);

            setShowCheckOutModal(false);

            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message);
            } else {
                alert('Gagal check-out. Silakan coba lagi.');
            }
        }
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container mt-0 px-0" style={{ backgroundColor: '#FFFFFF', borderRadius: '0px 0px 0 0', maxHeight: '85vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <Lokasi username={username} />
                    <div className="col-md-12">

                        {loading ? (
                            <p className='px-4'>Loading Tunggu Sebentar...</p>
                        ) : (
                            <div className="row justify-content-center mb-2 mt-4 px-0">
                                <div className="col-md-12">
                                    <ItemsCarousel
                                        requestToChangeActive={setActiveItemIndex}
                                        activeItemIndex={activeItemIndex}
                                        numberOfCards={1}
                                        gutter={-25}
                                        outsideChevron
                                        chevronWidth={0}
                                    >
                                        {outlets.map((outlet, index) => {

                                            return (
                                                <div
                                                    key={index}
                                                    className="card mb-2"
                                                    style={{ cursor: 'pointer', marginRight: '15px', marginLeft: '15px', background: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}
                                                >
                                                    <div className="card-body position-relative p-0 m-0 shadow-sm">
                                                        <div className="p-2 mt-0">
                                                            <Table className='mb-0' style={{ fontSize: '13px', borderRadius: '10px', backgroundColor: 'transparent' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={2} className="fw-bold mb-1" style={{ backgroundColor: 'transparent', color: '#FFF' }}>
                                                                            <FaStore size={22} /> &nbsp;
                                                                            <span style={{ fontSize: '14px' }}>{outlet.nama_outlet}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <br />
                                                                    <tr>
                                                                        <td style={{ width: '40%', border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 pt-1 pb-0">
                                                                            Tipe Outlet
                                                                        </td>
                                                                        <td style={{ border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 pt-1 pb-0">: {outlet.fisik}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 p-0">Outlet ID</td>
                                                                        <td style={{ border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 p-0">: {outlet.outlet_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 p-0">Jarak</td>
                                                                        <td style={{ border: '0', color: '#FFF', backgroundColor: 'transparent' }} className="px-2 p-0">: {Math.abs(outlet.distance).toLocaleString()} Meter</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            <center>
                                                                <div className="bottom-0 start-10 mb-1">
                                                                    <button
                                                                        onClick={() => {
                                                                            if (outlet.checkin_status === '0') {
                                                                                handleCheckInClick(outlet, 1);
                                                                            } else if (outlet.checkin_status === '1') {
                                                                                handleCheckOutClick(outlet, 1);
                                                                            }
                                                                        }}
                                                                        disabled={outlet.checkin_status === '2' || parseFloat(outlet.distance) > 30000}
                                                                        style={{
                                                                            backgroundColor: parseFloat(outlet.distance) > 30000 ? '#6c757d' : (outlet.checkin_status === '0' || outlet.checkin_status === '1' ? '#E3AC14' : '#28A745'),
                                                                            color: 'white',
                                                                            border: 'none',
                                                                            padding: '7px 10px',
                                                                            marginTop: '15px',
                                                                            borderRadius: '15px',
                                                                            fontSize: '11px',
                                                                            width: '30%',
                                                                            cursor: parseFloat(outlet.distance) <= 30000 && (outlet.checkin_status === '0' || outlet.checkin_status === '1') ? 'pointer' : 'not-allowed'
                                                                        }}
                                                                    >
                                                                        {parseFloat(outlet.distance) > 30000 ? 'So Far' :
                                                                            outlet.checkin_status === '0' ? 'Check In' :
                                                                                outlet.checkin_status === '1' ? 'Checkout' : 'Visited'
                                                                        }
                                                                    </button>
                                                                    {outlet.checkin_status === '0' && (
                                                                        <button
                                                                            onClick={() => {
                                                                                handleCheckInClick(outlet, 0);
                                                                            }}
                                                                            disabled={outlet.checkin_status === '2' || parseFloat(outlet.distance) > 30000}
                                                                            style={{
                                                                                backgroundColor: parseFloat(outlet.distance) > 30000 ? '#6c757d' : (outlet.checkin_status === '0' || outlet.checkin_status === '1' ? '#E3AC14' : '#28A745'),
                                                                                color: 'white',
                                                                                border: 'none',
                                                                                padding: '7px 10px',
                                                                                marginTop: '15px',
                                                                                borderRadius: '15px',
                                                                                fontSize: '11px',
                                                                                width: '30%',
                                                                                cursor: parseFloat(outlet.distance) <= 30000 && (outlet.checkin_status === '0' || outlet.checkin_status === '1') ? 'pointer' : 'not-allowed'
                                                                            }}
                                                                            className="mx-2"
                                                                        >
                                                                            Tutup
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </ItemsCarousel>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal for Check-In Confirmation */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedOutlet?.status === 1 ? 'Confirm Check-In' : 'Confirm Close Outlet'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedOutlet?.status === 1
                        ? `Apakah Anda ingin Check-in dan melanjutkan survey di ${selectedOutlet?.nama_outlet}?`
                        : 'Apakah Anda akan mengkonfirmasi bahwa outlet tutup?'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleCheckInConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Check-Out Confirmation */}
            <Modal show={showCheckOutModal} onHide={() => setShowCheckOutModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Check-Out</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Apakah Anda ingin Check-out dari <b>{selectedOutlet ? selectedOutlet.nama_outlet : ''}</b>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCheckOutModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleCheckOutConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Dashboard;


