import React from 'react';
import BannerSlide from './BannerSlide';

const News = () => {
    return (
        <div className="homepage-container">
            <div className="homepage-content">
                <div className="row justify-content-center">                    
                    <div className="col-md-12 pt-2 px-3">
                        <BannerSlide />
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default News
