import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { id as idLocale } from 'date-fns/locale';

const HistorySurveyDetail = ({ onLogout }) => {
  const [datane, setDatane] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { outlet_id, username, nama_outlet } = useParams();

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER}/data/show-history-detail`, { params: { usr: username, outlet_id, page } })
      .then(response => {
        setDatane(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Profile:', error);
        setLoading(false);
      });
  }, [username, outlet_id, page]);
  

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const formatDate = (dateString) => {
    return format(parseISO(dateString), 'EEEE, dd MMMM yyyy', { locale: idLocale });
  };

  return (
    <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}>
      <div className="container-fluid mt-0 pt-3 pb-4 px-4">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <p className="card-title mt-0 mb-0 text-white">History Detail Survey</p>
          </div>
        </div>
      </div>
      <div className="container mt-0 px-1 bg-light" style={{ borderRadius: '0px 0px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
        <div className="row justify-content-center">
          <div className="col-md-12 pt-5">
            {loading ? (
              <p>Loading Tunggu Sebentar...</p>
            ) : (
              <>
                <div className="row justify-content-center mb-2 mt-1">
                  <div className="col-md-12">
                    <p>{outlet_id} - {nama_outlet}</p>
                    {datane.map((usernya, index) => (
                      <div 
                        key={index} 
                        className="card mb-2" 
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="card-body position-relative p-0 m-0 shadow-sm">
                          <div className="p-1 mt-0">
                            <Table className='mb-0' style={{ fontSize: '13px', borderRadius: '10px'}}>
                              <tbody>
                                <tr>
                                    <td colSpan={2} className="fw-bold mb-0 px-2 py-1"> 
                                        {formatDate(usernya.tgl_visit)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width:'40%', border:'0'}} className="px-2 p-0 pt-1">Posmat</td>
                                    <td style={{ border:'0', color: usernya.posmat === '1' ? 'green' : 'red' }} className="px-2 p-0 pt-1">
                                      : {usernya.posmat === '1' ? 'Tersedia' : 'Belum Tersedia'}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{border:'0'}} className="px-2 p-0">Knowledge</td>
                                    <td style={{ border:'0', color: usernya.knowledge === '1' ? 'green' : 'red' }} className="px-2 p-0 pt-1">
                                      : {usernya.knowledge === '1' ? 'Paham' : 'Belum Paham'}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{border:'0'}} className="px-2 p-0">Resosialisasi</td>
                                    <td style={{ border:'0', color: usernya.knowledge_note === '1' ? 'green' : 'red' }} className="px-2 p-0 pt-1">
                                      : {usernya.knowledge_note === '1' ? 'YES' : 'NO'}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{border:'0'}} className="px-2 p-0">IndiHome Digipos</td>
                                    <td style={{ border:'0', color: usernya.indihome_digipos === '1' ? 'green' : 'red' }} className="px-2 p-0 pt-1">
                                      : {usernya.indihome_digipos === '1' ? 'Paham' : 'Belum Paham'}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{border:'0'}} className="px-2 p-0">PIC Eskalasi</td>
                                    <td style={{border:'0'}} className="px-2 p-0">: {usernya.pic_eskalasi ? usernya.pic_eskalasi : '-'}</td>
                                </tr>
                                <tr>
                                    <td style={{border:'0'}} className="px-2 p-0">Feedback</td>
                                    <td style={{border:'0', verticalAlign: 'top'}} className="px-2 p-0">: {usernya.feedback ? usernya.feedback : '-'}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {page > 1 && <FaArrowCircleLeft onClick={handlePrevious} size={35} className='mx-1'/>}
                  <span>Page {page} of {totalPages}</span>
                  {page < totalPages && <FaArrowCircleRight onClick={handleNext} size={35} className='mx-1'/>}
                </div>
              </>
            )}
            <br/><br/><br/><br/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistorySurveyDetail;
