import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
//import BottomNavigationBar from './BottomNavigationBar';

const FormSurvey = ({username}) => {
    const { outlet_id } = useParams();
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [message, setMessage] = useState('');
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const date = new Date();
        const formattedDate = date.toLocaleDateString('id-ID', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        setCurrentDate(formattedDate);
    }, []);

    const handleOptionClick1 = (value) => {
        setQuestion1(value);
    };
    const handleOptionClick2 = (value) => {
        setQuestion2(value);
    };
    const handleOptionClick3 = (value) => {
        setQuestion3(value);
    };
    const handleOptionClick4 = (value) => {
        setQuestion4(value);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLongitude(position.coords.longitude);
            setLatitude(position.coords.latitude);
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER}/data/submit-survey`, {
            username,
            outlet_id,
            question1,
            question2,
            question3,
            question4,
            question5,
            question6,
            longitude,
            latitude
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
            } else {
                setMessage(response.data.message); 
            }
        }).catch((error) => {
            //console.error('There was an error submitting the survey:', error);
            setMessage('Failed to submit survey due to an error.');
        });
    };
  return (
    <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
        <div className="container-fluid mt-0 pt-3 pb-4 px-4">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <p className="card-title mt-0 mb-0 text-white">Form Survey</p>
                </div>
            </div>
        </div>
                
        <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
            <div className="row justify-content-center">
                <div className="col-md-12 pt-5">
                    <form onSubmit={handleSubmit}>
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-0 p-0" style={{ fontSize: '13px'}}>
                                            <label>Outlet ID : {outlet_id}</label>
                                        </div>
                                        <div className="m-0 p-0" style={{ fontSize: '13px'}}>
                                            <label>Tanggal : {currentDate}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-1" style={{ fontSize: '13px' }}>
                                            <p><label>Posmat sudah tersedia di Outlet?</label></p>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question1 === '1' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left',
                                                }}
                                                onClick={() => handleOptionClick1('1')}
                                            >
                                                <input
                                                type="radio"
                                                name="question1"
                                                value="1"
                                                checked={question1 === '1'}
                                                onChange={(e) => setQuestion1(e.target.value)}
                                                required
                                                /> Tersedia
                                            </div>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question1 === '0' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left',
                                                }}
                                                onClick={() => handleOptionClick1('0')}
                                            >
                                                <input
                                                type="radio"
                                                name="question1"
                                                value="0"
                                                checked={question1 === '0'}
                                                onChange={(e) => setQuestion1(e.target.value)}
                                                required
                                                /> Belum Tersedia
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body mb-0">
                                        <div className="mb-1" style={{fontSize: '13px'}}>
                                            <p><label>Outlet paham produk IndiHome?</label></p>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question2 === '1' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left', // Set text alignment to left
                                                }}
                                                onClick={() => handleOptionClick2('1')}
                                            >
                                                <input
                                                type="radio"
                                                name="question2"
                                                value="1"
                                                checked={question2 === '1'}
                                                onChange={(e) => setQuestion2(e.target.value)}
                                                required
                                                /> Paham
                                            </div>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question2 === '0' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left',
                                                }}
                                                onClick={() => handleOptionClick2('0')}
                                            >
                                                <input
                                                type="radio"
                                                name="question2"
                                                value="0"
                                                checked={question2 === '0'} // Set checked based on question1 value
                                                onChange={(e) => setQuestion2(e.target.value)}
                                                required
                                                /> Belum Paham
                                            </div>
                                        </div>
                                    
                                        {question2 === '0' && (
                                            <div className="mb-1 mt-4" style={{fontSize: '13px'}}>
                                                <p><label>Jika, belum paham, sudah di re-sosialisasi?</label></p>
                                                <div
                                                    className="card-body p-2 mb-1"
                                                    style={{
                                                    border: '1px solid #CCC',
                                                    borderRadius: '5px',
                                                    backgroundColor: question3 === '1' ? '#DEDEDE' : 'transparent',
                                                    textAlign: 'left', // Set text alignment to left
                                                    }}
                                                    onClick={() => handleOptionClick3('1')}
                                                >
                                                    <input
                                                    type="radio"
                                                    name="question3"
                                                    value="1"
                                                    checked={question3 === '1'}
                                                    onChange={(e) => setQuestion3(e.target.value)}
                                                    required
                                                    /> Sudah
                                                </div>
                                                <div
                                                    className="card-body p-2 mb-1"
                                                    style={{
                                                    border: '1px solid #CCC',
                                                    borderRadius: '5px',
                                                    backgroundColor: question3 === '0' ? '#DEDEDE' : 'transparent',
                                                    textAlign: 'left',
                                                    }}
                                                    onClick={() => handleOptionClick3('0')}
                                                >
                                                    <input
                                                    type="radio"
                                                    name="question3"
                                                    value="0"
                                                    checked={question3 === '0'} // Set checked based on question1 value
                                                    onChange={(e) => setQuestion3(e.target.value)}
                                                    required
                                                    /> Belum 
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>            
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body mb-0">
                                        <div className="mb-1" style={{fontSize: '13px'}}>
                                            <p><label>Outlet sudah paham menggunakan menu IndiHome Digipos?</label></p>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question4 === '1' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left', // Set text alignment to left
                                                }}
                                                onClick={() => handleOptionClick4('1')}
                                            >
                                                <input
                                                type="radio"
                                                name="question4"
                                                value="1"
                                                checked={question4 === '1'}
                                                onChange={(e) => setQuestion4(e.target.value)}
                                                required
                                                /> Paham
                                            </div>
                                            <div
                                                className="card-body p-2 mb-1"
                                                style={{
                                                border: '1px solid #CCC',
                                                borderRadius: '5px',
                                                backgroundColor: question4 === '0' ? '#DEDEDE' : 'transparent',
                                                textAlign: 'left',
                                                }}
                                                onClick={() => handleOptionClick4('0')}
                                            >
                                                <input
                                                type="radio"
                                                name="question4"
                                                value="0"
                                                checked={question4 === '0'} // Set checked based on question1 value
                                                onChange={(e) => setQuestion4(e.target.value)}
                                                required
                                                /> Belum Paham
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body mb-0">
                                        <div className="mb-1" style={{fontSize: '13px'}}>
                                            <p><label>Outlet feedback & support needed</label></p>
                                            <div>
                                            <textarea 
                                                value={question5} 
                                                onChange={(e) => setQuestion5(e.target.value)} 
                                                className="form-control" 
                                                style={{fontSize: '13px'}}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-3">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body mb-0">
                                        <div className="mb-1" style={{fontSize: '13px'}}>
                                            <p><label>PIC Eskalasi</label></p>
                                            <div>
                                                <select 
                                                    value={question6} 
                                                    onChange={(e) => setQuestion6(e.target.value)} 
                                                    className="form-select"
                                                    style={{fontSize: '13px'}}
                                                >
                                                    <option value=''>Tidak Perlu Eskalasi</option>
                                                    <option value="Manager">Manager HH Area</option>
                                                    <option value="Supervisor">Supervisor</option>
                                                    <option value="Staff">Staff</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <button type="submit" className="btn btn-danger">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {message && <p className="mt-3 text-center">{message}</p>}
                    <br/><br/><br/><br/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FormSurvey
