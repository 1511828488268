import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Offcanvas from 'react-bootstrap/Offcanvas';

// Import images
import img1 from '../components/images/1.png';
import img2 from '../components/images/2.png';
import img3 from '../components/images/3.png';
import img4 from '../components/images/4.png';

const BannerSlide = () => {
    const [index, setIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [programHeader, setProgramHeader] = useState('');
    const [programKonten, setProgramKonten] = useState('');

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleShow = (text) => {
        let header = '';
        let konten = '';

        switch (text) {
            case 1:
                header = "EZnet by Telkomsel <br> Internet rumah murah & mudah untuk semua";
                konten = `
                    EZnet by Telkomsel adalah salah satu layanan broadband dari Telkomsel yang menghadirkan jaringan fixed, guna memberikan pengalaman internet di rumah yang tejangkau bagi pelanggan. Saat ini, layanan yang tersedia adalah layanan internet dengan kecepatan 10Mbps. 
                    <br><br>Biaya berlangganan EZnet by Telkomsel dengan kecepatan internet 10Mbps adalah Rp150.000/bulan untuk wilayah Jawa dan Bali, Rp170.000/bulan untuk wilayah Sumatra, dan Rp200.000/bulan untuk wilayah Papua, Maluku, Sulawesi, Kalimantan, dan Nusa Tenggara. Harga belum termasuk PPN. 
                    <br><br>Kontrak berlangganan EZnet by Telkomsel untuk seluruh paket berlaku selama minimum 12 (dua belas) bulan setelah layanan EZnet by Telkomsel berstatus aktif.`;
                break;
            case 2:
                header = `LIGA SALES "WALI" UNTUK CHANNEL SALES FORCE`;
                konten = `
                    <b>Deskripsi:</b><br>
                    Program Insentif untuk memberikan penghargaan kepada Sales Force TERBAIK di masing-masing Region.
                    <br>
                    <b>Syarat & Ketentuan:</b><br>
                    <ul>
                        <li>Periode: 1 - 31 Juli 2024;</li>
                        <li>Besaran Insentif adalah Rp. 1.000.000,- per BEST SF di masing-masing Region pada periode program;</li>
                        <li>PS yang dihitung merupakan PS yang diperoleh selama periode program;</li>
                        <li>Mekanisme distribusi: untuk SF: pemberian insentif melalui poin MIP yang dapat diredeem oleh masing-masing SF ke LinkAja setelah periode rekon (N+1).</li>
                    </ul>                
                `;
                break;
            case 3:
                header = `Insentif POI P1 SIFA untuk Channel SALES FORCE (SF) AGENSI`;
                konten = `
                    <b>Deskripsi:</b><br>
                    Program Insentif untuk meningkatkan peluang penjualan para Sales Force dalam mencapai Target Sales yang telah diberikan serta mendorong Sales Force dengan mendatangi lokasi arahan Target POI P1 SIFA yang telah ditentukan dan melakukan penjualan di sana.
                    <br><br>
                    <b>Syarat & Ketentuan:</b><br>
                    <ul>
                        <li>Periode: 9 - 31 Juli 2024;</li>
                        <li>Besaran Insentif: Rp5.000,- per RE yang menjadi PS paling lambat 7 Agustus 2024 oleh SF di POI P1 dan akan diberikan untuk 10 RE pertama;</li>
                        <li>Data baseline POI P1 SIFA disepakati adalah: Data POI P1 SIFA per 8 Juli 2024;</li>
                        <li>Distribusi Poin akan dilakukan setelah rekon pada bulan (N+1).</li>
                    </ul>
                `;
                break;
            case 4:
                header = `SALES GAMBIT untuk Channel SALES FORCE (SF) AGENSI`;
                konten = `
                    <b>Deskripsi:</b><br>
                    Program Merit Incentive untuk menjaga semangat SF dalam berjualan IndiHome dengan prinsip "Lebih banyak PS, lebih banyak CUAN".
                    <br><br>
                    <b>Syarat & Ketentuan:</b><br>
                    <ul>
                        <li>SF class Juli ditentukan melalui performansi PS dari SF di bulan Juni 2024, perubahan class berikutnya di bulan Agustus;</li>
                        <li>SF baru (LoS < 1 bulan) dan/atau SF yang berada di kelas Black berhak untuk mendapatkan insentif Morning Briefing (clock-in MB incentive);</li>
                        <li>Gold & Platinum minimum LoS 1 Bulan;</li>
                        <li>Tambahan extra benefits Communication & BPJS diberikan dalam bentuk Poin di MIP;</li>
                        <li>PS Coefficient untuk produk dengan ARPU <200 ribu = 0.8;</li>
                        <li>Incentive Coefficient digunakan ketika terdapat program tematik dari HQ pada bulan berjalan;</li>
                        <li>Untuk pengecekan Kelas SF dapat melalui:</li>
                        <li>Link: tsel.id/SalesGambitList;</li>
                        <li>Telegram bot: @monitoring_mip_bot</li>                    
                    </ul>
                `;
                break;
            default:
                break;
        }
        
        setProgramHeader(header);
        setProgramKonten(konten);
        setShow(true);
    };

    const handleClose = () => setShow(false);
    
    return (
        <div>
            <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} controls={false}>
                <Carousel.Item style={{ paddingRight: '5px' }}>
                    <img
                        className="d-block w-100"
                        src={img1}
                        height={150}                    
                        alt="Promo 1"
                        style={{ borderRadius: '10px' }}
                    />
                    <div className='text-end pt-1 px-1' style={{ fontSize: '12px', alignItems: 'end', cursor: 'pointer' }} onClick={() => handleShow(1)}>
                        Selengkapnya
                    </div>
                </Carousel.Item>
                <Carousel.Item style={{ paddingRight: '5px' }}>
                    <img
                        className="d-block w-100"
                        src={img2}
                        height={150}
                        alt="Promo 2"
                        style={{ borderRadius: '10px' }}
                    />
                    <div className='text-end pt-1 px-1' style={{ fontSize: '12px', alignItems: 'end', cursor: 'pointer' }} onClick={() => handleShow(2)}>
                        Selengkapnya
                    </div>
                </Carousel.Item>
                <Carousel.Item style={{ paddingRight: '5px' }}>
                    <img
                        className="d-block w-100"
                        src={img3}
                        height={150}
                        alt="Promo 3"
                        style={{ borderRadius: '10px' }}
                    />
                    <div className='text-end pt-1 px-1' style={{ fontSize: '12px', alignItems: 'end', cursor: 'pointer' }} onClick={() => handleShow(3)}>
                        Selengkapnya
                    </div>
                </Carousel.Item>
                <Carousel.Item style={{ paddingRight: '5px' }}>
                    <img
                        className="d-block w-100"
                        src={img4}
                        height={150}
                        alt="Promo 4"
                        style={{ borderRadius: '10px' }}
                    />
                    <div className='text-end pt-1 px-1' style={{ fontSize: '12px', alignItems: 'end', cursor: 'pointer' }} onClick={() => handleShow(4)}>
                        Selengkapnya
                    </div>
                </Carousel.Item>
            </Carousel>

            <Offcanvas show={show} onHide={handleClose} placement="bottom" style={{ borderRadius: '20px 20px 0 0', height: '80%' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Information Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ fontSize: '13px' }}>
                    <h6 dangerouslySetInnerHTML={{ __html: programHeader }} className="mb-3"></h6>
                    <p dangerouslySetInnerHTML={{ __html: programKonten }}></p>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default BannerSlide
