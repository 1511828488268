import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from './images/login.png';

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const [location, setLocation] = useState(null);
    const [city, setCity] = useState('');
    const [locationAllowed, setLocationAllowed] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            setLocation('Geolocation tidak didukung oleh browser ini.');
            setLocationAllowed(false);
        }
    }, []);

    const showPosition = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation(`${latitude}, ${longitude}`);
        
        fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
            .then(response => response.json())
            .then(data => {
                const city = data.address.city || data.address.town || data.address.village || "Tidak ditemukan";
                setCity(city);
            })
            .catch(error => {
                console.log('Error:', error);
                setLocationAllowed(false);
            });
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setLocation("Pengguna menolak permintaan geolocation.");
                setLocationAllowed(false);
                break;
            case error.POSITION_UNAVAILABLE:
                setLocation("Informasi lokasi tidak tersedia.");
                setLocationAllowed(false);
                break;
            case error.TIMEOUT:
                setLocation("Permintaan untuk mendapatkan lokasi melebihi batas waktu.");
                setLocationAllowed(false);
                break;
            case error.UNKNOWN_ERROR:
                setLocation("Terjadi kesalahan yang tidak diketahui.");
                setLocationAllowed(false);
                break;
            default:
                setLocation("Terjadi kesalahan yang tidak diketahui.");
                setLocationAllowed(false);
        }
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (!locationAllowed) {
            alert("Login gagal karena lokasi tidak diaktifkan");
            return;
        }
        
        axios.post(`${process.env.REACT_APP_SERVER}/auth/login`, {
            username,
            password,
            location,
            city
        }, { withCredentials: true }).then((response) => {            
            if (response.data.changePassword) {
                onLogin(response.data);
                navigate(`/ganti-password/${response.data.username}/${response.data.flag_user}`);
                
            } else if (response.data.loggedIn) {
                onLogin(response.data);
                navigate('/homepage');
            } else {
                setMessage(response.data.message);
            }
        });
    };

    return (
        <div className='p-0 m-0' style={{ overflow: 'hidden' }}>
            <Container fluid className='m-0 p-0'>
                <Row className='g-0 justify-content-center align-items-center' style={{ minHeight:'100vh'}}>
                    <Col xs={0} sm={1} md={2} lg={1}></Col>
                    <Col xs={12} sm={10} md={8} lg={10}>
                        <Col sm={12} xs={12} md={12} lg={12} className='d-flex align-items-end justify-content-center' style={{ paddingRight:'0px',border: '0'}}>
                            <div className='px-0 py-0' style={{ borderBottomRightRadius: '0px'}}>
                                <p className="text-left px-5 pt-4 m-0 text-danger" style={{ fontSize:'30px', fontWeight:'bold' }}>FANNY</p>
                                <p className="text-left px-5 m-0 text-dark-light" style={{ fontSize:'15px' }}>FMC Management System</p>
                                <center>
                                    <img src={logo} className='mx-0' alt="Login FMC" width={'80%'} />
                                </center>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={12} className='d-flex flex-column align-items-center justify-content-top p-0' style={{ border: '0'}}>
                            <div className='p-4' style={{ width:'90%', backgroundImage: 'linear-gradient(#DC3545, #9F4C51)', borderRadius: '20px', border:'1px solid #DC3545' }}>
                                <form onSubmit={handleLogin}>
                                    <div className="form-group mb-3">
                                        <label className='text-light'>NIK atau Username</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className='text-light'>Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)} 
                                            required 
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-dark w-100 p-2">Login</button>
                                </form>
                                {
                                    message && 
                                    <p className="mt-3 p-2 text-center text-dark bg-warning" style={{ borderRadius:'7px', fontSize: '13px', fontWeight: 'bold'}}>
                                        {message}
                                    </p>
                                }
                            </div>
                            <div className="p-1 mt-3 mb-0" style={{ width: '100%'}}>
                                <p className="text-center px-3 m-0" style={{ fontSize:'11px'}}> By Reporting & Analytics Dashboard Management - CBPA</p>
                                <p className="text-center px-3 m-0" style={{ fontSize:'11px'}}> copyright@2024 - version 1.0</p>
                            </div>
                        </Col>
                    </Col>
                    <Col xs={0} sm={1} md={2} lg={1}></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;
