import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';

const TopFiveProduct = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_SERVER}/data/get-top-product`, {
        withCredentials: true
    })
        .then((response) => {
            if (response.data.success) {
                setProducts(response.data.products);
            } else {
                console.error('Failed to fetch data.');
            }
        })
        .catch(() => {
            console.error('Failed to fetch data due to an error.');
        });
  }, []);

  return (
    <div style={{ fontSize: '13px'}}>
        <p className="fw-bold">Top 5 Product in Digipos - Last Month</p>
        <ListGroup>
            {products.map((product, index) => (
                <ListGroup.Item key={index}>{product}</ListGroup.Item>
            ))}
        </ListGroup> 
    </div>
  )
}

export default TopFiveProduct
