import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateDjpHboBranch = ({ username, branch }) => {
    const [userId, setUserId] = useState('');
    const [hari, setHari] = useState('');
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [outletId, setOutletId] = useState('');
    const [outletSuggestions, setOutletSuggestions] = useState([]);

    const flag_user = "H";

    useEffect(() => {
        
        axios.get(`${process.env.REACT_APP_SERVER}/get-tip-hbo-users`, { 
            params: { branch, flag_user },
            withCredentials: true 
        })
        .then((response) => {
            if (response.data.success) {
                setUsers(response.data.users);
            } else {
                setMessage('Failed to fetch users.');
            }
        })
        .catch((error) => {
            setMessage('Failed to fetch users due to an error.');
        });
    }, [branch]);

    const handleOutletIdChange = (e) => {
        const value = e.target.value;
        setOutletId(value);

        if (value.length > 0) {
            axios.get(`${process.env.REACT_APP_SERVER}/get-outlet-suggestions-tip-hbo`, {
                params: { query: value, branch },
                withCredentials: true
            })
            .then((response) => {
                if (response.data.success) {
                    setOutletSuggestions(response.data.outlets);
                } else {
                    setOutletSuggestions([]);
                }
            })
            .catch((error) => {
                setOutletSuggestions([]);
            });
        } else {
            setOutletSuggestions([]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_SERVER}/submit-djp`, {
            username,
            outletId,
            hari,
            flag_user,
            userId
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
                setOutletId('');
            } else {
                setMessage(response.data.message); 
                setOutletId('');
            }
        }).catch((error) => {
            setMessage('Failed due to an error.');
            setOutletId('');
        });
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Create DJP HBO</p>
                    </div>
                </div>
            </div>
                    
            <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '10px 10px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-2 mb-2 mt-3">                    
                        <form onSubmit={handleSubmit}>                                                         
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-1">
                                    <div className="card">
                                        <div className="card-body pb-1">
                                            <div style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Nama User HBO</label>
                                                <div>
                                                    <select
                                                        required
                                                        className="form-select"
                                                        style={{ fontSize: '12px' }}
                                                        value={userId}
                                                        onChange={(e) => setUserId(e.target.value)}
                                                    >
                                                        <option value="">Select User HBO</option>
                                                        {users.map((user, index) => (
                                                            <option key={index} value={user.username}>
                                                                {user.username} - {user.nama_lengkap}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Pilih Hari</label>
                                                <div>
                                                    <select 
                                                        required 
                                                        className="form-select" 
                                                        style={{ fontSize: '12px' }}
                                                        onChange={(e) => setHari(e.target.value)}
                                                    >
                                                        <option value="">Select Hari</option>
                                                        <option value='SENIN'>Senin</option>
                                                        <option value='SELASA'>Selasa</option>
                                                        <option value='RABU'>Rabu</option>
                                                        <option value='KAMIS'>Kamis</option>
                                                        <option value='JUMAT'>Jumat</option>
                                                        <option value='SABTU'>Sabtu</option>
                                                        <option value='MINGGU'>Minggu</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Pilih Outlet</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{ fontSize: '12px' }}
                                                        value={outletId}
                                                        onChange={handleOutletIdChange}
                                                        placeholder="Masukkan Outlet ID atau Nama Outlet"
                                                    />
                                                    {outletSuggestions.length > 0 && (
                                                        <ul className="list-group" style={{ fontSize: '12px' }}>
                                                            {outletSuggestions.map((outlet, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="list-group-item list-group-item-action"
                                                                    onClick={() => {
                                                                        setOutletId(outlet.outlet_id);
                                                                        setOutletSuggestions([]);
                                                                    }}
                                                                >
                                                                    {outlet.outlet_id} - {outlet.nama_outlet}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="card m-3">
                                            <button type="submit" className="btn btn-danger p-2">Create DJP</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {message && <p className="mt-3 text-center">{message}</p>}
                        <br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateDjpHboBranch;
