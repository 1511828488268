import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

const DetailOutlet = ({ username }) => {
    const navigate = useNavigate();
    const { outlet_id } = useParams();
    const [outlet, setOutlet] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOutletDetail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/get-outlet-detail?id=${outlet_id}`);
                setOutlet(response.data.outlets[0]); // Ambil outlet pertama dari array outlets
                setLoading(false);
            } catch (error) {
                console.error('Error fetching outlets:', error);
                setLoading(false);
            }
        };

        fetchOutletDetail();
    }, [outlet_id]);

    if (loading) {
        return <p>Loading outlet details...</p>;
    }

    if (!outlet) {
        return <p>No outlet available.</p>;
    }

    const handleCardClick = (id, username, nama_outlet) => {
        navigate(`/detail-survey/${id}/${username}/${nama_outlet}`);
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4" style={{ backgroundImage: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Detail Outlet</p>
                    </div>
                </div>
            </div>
            <div className="container mt-0 px-1" style={{ borderRadius: '0px 0px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-5">
                        {loading ? (
                            <p className="mt-3 text-center">Loading...</p>
                        ) : (
                            <>
                                <div className="row justify-content-center mb-2 mt-1">
                                    <div className="col-md-12">
                                        <div 
                                            key={outlet.OUTLET_ID} 
                                            className="card mb-2" 
                                            onClick={() => handleCardClick(outlet.OUTLET_ID, username, outlet.NAMA_OUTLET)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className="card-body position-relative p-0" style={{ backgroundColor: '#EFEFEF' }}>
                                                <div className="p-3" style={{ fontSize: '13px' }}>
                                                    <Table className='mb-0'>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0'}} >
                                                                    PROFIL OUTLET
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Outlet Id</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.OUTLET_ID}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Nama Outlet</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.NAMA_OUTLET}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>TIPE</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.fisik}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Area Name</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.AREA_NAME}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Regional</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.REGIONAL}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Branch</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.BRANCH}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Cluster</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.CLUSTER_NAME}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>City</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KOTA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Kecamatan</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KECAMATAN}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Kelurahan</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KELURAHAN}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Longitude</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.LONGITUDE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Latitude</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.LATITUDE}</td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'15px 7px 0 7px', border: '0'}}> 
                                                                    PERFORMANCE BULAN INI
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Total Visit</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.total_call_now).toLocaleString()} Kali</td>
                                                            </tr>

                                                            <tr>
                                                                <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'15px 7px 0 7px', border: '0'}}> 
                                                                    PERFORMANCE TAHUN INI
                                                                </td>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Total Visit</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.total_call_total} Kali</td>
                                                            </tr>

                                                            <tr>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Last Visited</td>
                                                                <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.tgl_max}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <div className="position-absolute top-50 end-0 translate-middle-y px-3">
                                                    <FaAngleRight size={22} style={{ color: '#333333' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <br/><br/><br/><br/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailOutlet;
