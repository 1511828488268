import React from 'react';
import { FaCalendarAlt, FaCheckSquare, FaBook, FaTasks } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

const GridMainMenu = () => {
    const navigate = useNavigate();
    const routeSurveyHistory = () => {
        navigate(`/history`);
    }

    const routeProductCatalog = () => {
        navigate(`/product-catalog`);
    }

    const routeDjpSchedule = () => {
        navigate(`/djp-schedule`);
    }

    const routeCheckinStatus = () => {
        navigate(`/dashboard`);
    }


    return (
        <div>
            <Container>
                <Row className="g-4 mb-1">
                    <Col xs={3} className="d-flex flex-column align-items-center text-center p-1">
                        <div
                            onClick={routeDjpSchedule}
                            className="p-2 d-flex align-items-center justify-content-center mb-1"
                            style={{
                                //backgroundColor: '#DC3545',
                                background: 'linear-gradient(to right, #8F0B3D, #DC3545)',
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                            }}
                        >
                            <FaCalendarAlt size={20} color="white" className="mb-0" /> <br />

                        </div>
                        DJP <br /> Schedule
                    </Col>
                    <Col xs={3} className="d-flex flex-column align-items-center text-center p-1">
                        <div
                            onClick={routeCheckinStatus}
                            className="p-2 d-flex align-items-center justify-content-center mb-1"
                            style={{
                                //backgroundColor: '#DC3545',
                                background: 'linear-gradient(to right, #8F0B3D, #DC3545)',
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                            }}
                        >
                            <FaCheckSquare size={20} color="white" className="mb-0" /> <br />
                        </div>
                        Checkin <br /> Now
                    </Col>
                    <Col xs={3} className="d-flex flex-column align-items-center text-center p-1">
                        <div
                            onClick={routeProductCatalog}
                            className="p-2 d-flex align-items-center justify-content-center mb-1"
                            style={{
                                //backgroundColor: '#DC3545',
                                background: 'linear-gradient(to right, #8F0B3D, #DC3545)',
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                            }}
                        >
                            <FaBook size={20} color="white" className="mb-0" /> <br />
                        </div>
                        Product <br /> Catalog
                    </Col>
                    <Col xs={3} className="d-flex flex-column align-items-center text-center p-1">
                        <div
                            onClick={routeSurveyHistory}
                            className="p-2 d-flex align-items-center justify-content-center mb-1"
                            style={{
                               //backgroundColor: '#DC3545',
                               background: 'linear-gradient(to right, #8F0B3D, #DC3545)',
                                borderRadius: '50%',
                                width: '60px',
                                height: '60px',
                            }}
                        >
                            <FaTasks size={20} color="white" className="mb-0" /> <br />
                        </div>
                        Survey <br /> History
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GridMainMenu
