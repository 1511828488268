import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaKey, FaPlusCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Profile = ({ username, onLogout }) => {
    const [datane, setDatane] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER}/show-profile?usr=${username}`)
            .then(response => {
                setDatane(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching Profile:', error);
                setLoading(false);
            });
    }, [username]);

    const handleChangePassword = (flagnya) => {
        navigate(`/ganti-password/${username}/${flagnya}`);
    };

    const handleCreateUserHbo = () => {
        navigate(`/create-hbo-user/${username}`);
    };

    const handleCreateUserHboBranch = () => {
        navigate(`/create-hbo-user-branch/`);
    };

    const handleCreateDjpTipCluster = () => {
        navigate(`/create-djp-tip-cluster/`);
    };

    const handleCreateDjpHboBranch = () => {
        navigate(`/create-djp-hbo-branch/`);
    };

    const handleCreateDjpOrganik = () => {
        navigate(`/create-djp-organik/`);
    };

    const handleCreateUserInternal = () => {
        navigate(`/create-internal-user/${username}`);
    };

    const handleCreateUserInternalArea = (area) => {
        navigate(`/create-internal-user-area/${username}/${area}`);
    };

    const handleLogout = () => {
        axios.post(`${process.env.REACT_APP_SERVER}/logout`, {}, { withCredentials: true })
            .then(() => {
                onLogout();
                navigate('/');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">User Profile</p>
                    </div>
                </div>
            </div>
            <div className="container mt-0 px-4 bg-white" style={{ borderRadius: '10px 10px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-3">
                        
                        {loading ? (
                            <p>Loading Tunggu Sebentar...</p>
                        ) : (
                            <>
                                <div className="row justify-content-center mb-2 mt-1">
                                    <div className="col-md-12">
                                        {datane.map((usernya, index) => (
                                            <div key={index} className="card-body position-relative p-0 m-0">
                                                <div className="p-2" style={{ fontSize: '12px' }}>
                                                    <p className='mb-2'> <span><b>USER LOGIN</b></span> <br /> <span>{username}</span> </p>
                                                    <p className='mb-2'> <span><b>NAMA LENGKAP</b></span> <br /> <span>{usernya.nama_lengkap}</span> </p>

                                                    {usernya.id_level === '0' && (
                                                        <p className='mb-2'>
                                                            <span><b>LOKASI</b></span> <br />
                                                            <span>Headquarters</span>
                                                        </p>
                                                    )}

                                                    {(usernya.id_level === '1' || usernya.id_level === '2' || usernya.id_level === '3' || usernya.id_level === '4') && (
                                                        <p className='mb-2'>
                                                            <span><b>AREA NAME</b></span> <br />
                                                            <span>{usernya.area_name}</span>
                                                        </p>
                                                    )}

                                                    {(usernya.id_level === '2' || usernya.id_level === '3' || usernya.id_level === '4') && (
                                                        <p className='mb-2'>
                                                            <span><b>REGIONAL</b></span> <br />
                                                            <span>{usernya.regional}</span>
                                                        </p>
                                                    )}

                                                    {(usernya.id_level === '3' || usernya.id_level === '4') && (
                                                        <p className='mb-2'>
                                                            <span><b>BRANCH</b></span> <br />
                                                            <span>{usernya.branch}</span>
                                                        </p>
                                                    )}

                                                    {usernya.id_level === '4' && (
                                                        <p className='mb-2'>
                                                            <span><b>CLUSTER</b></span> <br />
                                                            <span>{usernya.cluster_name}</span>
                                                        </p>
                                                    )}
                                                    
                                                    {usernya.flag_user === 'H' && (
                                                        <>
                                                            <p className='mb-5'> <span><b>STATUS</b></span> <br /> <span>HBO</span> </p>
                                                        </>
                                                    )}
                                                    {usernya.flag_user === 'T' && (
                                                        <>
                                                            <p className='mb-5'> <span><b>STATUS</b></span> <br /> <span>TIP</span> </p>
                                                        </>
                                                    )}

                                                    <br /><br />
                                                    
                                                    {(usernya.flag_user === 'O') && (
                                                        <div className="row">
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={handleCreateDjpOrganik} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1"/>
                                                                    <div>Self DJP</div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    )}

                                                    {(usernya.created_flag === '1') && (
                                                        <div className="row">
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={handleCreateUserInternal} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1"/>
                                                                    <div>User Organik</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={handleCreateUserHbo} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1" />
                                                                    <div>User HBO & TIP</div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    )}
                                                    {(usernya.created_flag === '4') && (
                                                        <div className="row">
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={() => handleCreateUserHboBranch()} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1" />
                                                                    <div>User HBO & TIP</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={() => handleCreateDjpHboBranch()} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1" />
                                                                    <div>Create DJP HBO</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         
                                                    )}
                                                    {(usernya.created_flag === '5') && (
                                                        <div className="row">
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                            <div onClick={() => handleCreateDjpTipCluster()} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1" />
                                                                    <div>Create DJP TIP</div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    )}
                                                    {(usernya.created_flag === '3') && (
                                                        <div className="row">
                                                            <div className="col-6 col-md-3 text-center mb-3">
                                                                <div onClick={() => handleCreateUserInternalArea(usernya.area_flag)} className="p-2 bg-secondary text-white rounded">
                                                                    <FaPlusCircle className="mb-1"/>
                                                                    <div>User Organik</div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    )}

                                                    <div className="row">
                                                        <div className="col-6 col-md-3 text-center mb-3">
                                                            <div onClick={() => handleChangePassword(usernya.flag_user)} className="p-2 bg-secondary text-white rounded">
                                                                <FaKey className="mb-1" />
                                                                <div>Password</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-3 text-center mb-3">
                                                            <div onClick={handleLogout} className="p-2 bg-secondary text-white rounded">
                                                                <FaSignOutAlt className="mb-1" />
                                                                <div>Logout</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <br /><br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;
