import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Homepage from './components/Homepage';
import FormSurvey from './components/FormSurvey';
import BottomNavigationBar from './components/BottomNavigationBar';
import PilihOutlet from './components/PilihOutlet';
import ChangePassword from './components/ChangePassword';
import Profile from './components/Profile';
import HistorySurvey from './components/HistorySurvey';
import ReportSurvey from './components/ReportSurvey';
import DetailOutlet from './components/DetailOutlet';
import CreateHboUser from './components/CreateHboUser';
import CreateHboUserBranch from './components/CreateHboUserBranch';
import CreateDjpHboBranch from './components/CreateDjpHboBranch';
import CreateDjpTipCluster from './components/CreateDjpTipCluster';
import CreateDjpOrganik from './components/CreateDjpOrganik';
import CreateInternalUser from './components/CreateInternalUser';
import CreateInternalUserArea from './components/CreateInternalUserArea';
import HistorySurveyDetail from './components/HistorySurveyDetail';
import DjpSchedule from './components/DjpSchedule';
import ProductCatalog from './components/ProductCatalog';
import CheckinStatus from './components/CheckinStatus';
import News from './components/News';
import Help from './components/Help';

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userData, setUserData] = useState({
      username: '',
      namaLengkap: '',
      level: '',
      flag_user: '',
      created_flag: '',
      area_flag: '',
      area_name: '',
      regional: '',
      branch:'',
      cluster_name: '',
    });

    const styles = {
      fontFamily: 'Poppins, sans-serif',
      minHeight: '100vh',
      overflow: 'hidden',
      backgroundColor: '#FFFFFF'
    };

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_SERVER}/auth/login`, { withCredentials: true })
          .then((response) => {
              if (response.data.loggedIn) {
                  handleLogin(response.data);
              }
          })
          .catch((error) => {
              console.error('Error fetching login status:', error);
          });
    }, []);

    const handleLogin = (userData) => {
        setLoggedIn(true);
        setUserData(userData);
        //alert(`Data user: ${JSON.stringify(userData)}`);
    };

    const handleLogout = () => {
      axios.post(`${process.env.REACT_APP_SERVER}/auth/logout`, {}, { withCredentials: true })
          .then(() => {
              setLoggedIn(false);
              setUserData({
                username: '',
                namaLengkap: '',
                level: '',
                flag_user: '',
                created_flag: '',
                area_flag: '',
                area_name: '',
                regional: '',
                branch:'',
                cluster_name: '',
              });
          });
    };
  
    return (
      <div style={styles} className="m-0 p-0">
        <Container fluid className='m-0 p-0'>
          <Row className='g-0 justify-content-top align-items-center'>
            <Col xs={0} sm={1} md={2} lg={4} className='p-0 m-0'></Col>
            <Col xs={12} sm={10} md={8} lg={4} style={{ maxHeight: '100vh' }}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={ loggedIn ? <Navigate to="/homepage" /> : <Login onLogin={handleLogin}/>} />
                  <Route path="/homepage" element={ loggedIn ? <Homepage username={userData.username} namaLengkap={userData.nama_lengkap} onLogout={handleLogout} /> : <Navigate to="/" />} />
                  <Route path="/dashboard" element={ loggedIn ? <Dashboard username={userData.username} namaLengkap={userData.nama_lengkap}/> : <Navigate to="/" />} />
                  <Route path="/ganti-password/:username/:flagnya" element={ loggedIn ? <ChangePassword /> : <Navigate to="/" />} />

                  <Route path="/djp-schedule" element={ loggedIn ? <DjpSchedule username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/checkin-status" element={ loggedIn ? <CheckinStatus username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/product-catalog" element={ loggedIn ? <ProductCatalog username={userData.username}/> : <Navigate to="/" />} />

                  <Route path="/survey/:outlet_id" element={ loggedIn ? <FormSurvey username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/detail-survey/:outlet_id/:username/:nama_outlet" element={ loggedIn ? <HistorySurveyDetail username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/history-detail/:outlet_id" element={ loggedIn ? <DetailOutlet username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/pilih-outlet" element={ loggedIn ? <PilihOutlet username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/profile" element={ loggedIn ? <Profile username={userData.username} onLogout={handleLogout}/> : <Navigate to="/" />} />
                  <Route path="/history" element={ loggedIn ? <HistorySurvey username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/news" element={ loggedIn ? <News username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/help" element={ loggedIn ? <Help username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/report-survey" element={ loggedIn ? <ReportSurvey username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/create-hbo-user/:username" element={ loggedIn && userData.created_flag === '1' ? <CreateHboUser username={userData.username} /> : <Navigate to="/" />} />
                  <Route path="/create-hbo-user-branch" element={ loggedIn && userData.created_flag === '4' ? <CreateHboUserBranch username={userData.username} areaflag={userData.area_flag} areaname={userData.area_name} regional={userData.regional} branch={userData.branch} /> : <Navigate to="/" />} />
                  <Route path="/create-djp-tip-cluster" element={ loggedIn && userData.created_flag === '5' ? <CreateDjpTipCluster username={userData.username} branch={userData.branch} /> : <Navigate to="/" />} />
                  <Route path="/create-djp-hbo-branch" element={ loggedIn && userData.created_flag === '4' ? <CreateDjpHboBranch username={userData.username} branch={userData.branch} /> : <Navigate to="/" />} />
                  <Route path="/create-djp-organik" element={ loggedIn && userData.flag_user === 'O' ? <CreateDjpOrganik username={userData.username}/> : <Navigate to="/" />} />
                  <Route path="/create-internal-user/:username" element={ loggedIn && userData.created_flag === '1' ? <CreateInternalUser username={userData.username} /> : <Navigate to="/" />} />
                  <Route path="/create-internal-user-area/:username/:area" element={ loggedIn && userData.created_flag === '3' ? <CreateInternalUserArea username={userData.username} areaflag={userData.area_flag}/> : <Navigate to="/" />} />
                </Routes>
                {loggedIn && <BottomNavigationBar onLogout={handleLogout}/>}
              </BrowserRouter> 
            </Col>
            <Col xs={0} sm={1} md={2} lg={4}></Col>
          </Row>
        </Container>             
      </div>
    );
}

export default App;
