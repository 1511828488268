import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PilihOutlet = ({ username }) => {
    const navigate = useNavigate();
    const [OutletId, setOutletId] = useState('');    
    const [message, setMessage] = useState('');
    const [outlets, setOutlets] = useState([]);
    const [loading, setLoading] = useState(false); // Initial loading state set to false

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when starting to fetch data

        // Get the user's location
        navigator.geolocation.getCurrentPosition(showPosition, showError);
    };

    const showPosition = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Perform the API call to submit OutletId along with coordinates
        axios.post(`${process.env.REACT_APP_SERVER}/pilih-outlet`, {
            OutletId,
            latitude,
            longitude
        }, { withCredentials: true }).then((response) => {
            setLoading(false); // Set loading to false after data is fetched
            if (response.data.success) {
                if (response.data.outlets.length > 0) {
                    setOutlets(response.data.outlets);
                    setMessage('');
                } else {
                    setMessage('Outlet tidak ditemukan.');
                    setOutlets([]);
                }
            } else {
                setMessage('Failed to submit survey.');
            }
        }).catch((error) => {
            setLoading(false); // Set loading to false in case of an error
            setMessage('An error occurred while fetching data.');
        });
    };

    const showError = (error) => {
        console.error('Error getting location:', error);
        setMessage('An error occurred while getting your location.');
        setLoading(false); // Set loading to false in case of error
    };

    const handleCardClick = (outlet) => {
        navigate(`/survey/${outlet.OUTLET_ID}`);
    };

    

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Form Survey Outlet</p>
                    </div>
                </div>
            </div>
            <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <form onSubmit={handleSubmit}>
                    <div className="row justify-content-center">
                        <div className="col-md-12 pt-5">
                            <div className="card">
                                <div className="card-body mb-0">
                                    <div className="mb-1" style={{ fontSize: '13px' }}>
                                        <p><label>Pilih Outlet ID</label></p>
                                        <div>
                                            <input
                                                type="number"
                                                value={OutletId} 
                                                onChange={(e) => setOutletId(e.target.value)} 
                                                className="form-control" 
                                                style={{ fontSize: '13px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-md-12">
                            <div className="card">
                                <button type="submit" className="btn btn-danger">Cari Outlet</button>
                            </div>
                        </div>
                    </div>
                </form>
                
                {message && <p className="mt-3 text-center">{message}</p>}
                {loading ? (
                    <p className="mt-3 text-center">Loading...</p>
                ) : (
                    outlets.length > 0 && (
                    <>
                        <p className="mt-5 mb-2" style={{fontSize: '13px'}}>Outlet available untuk di survey :</p>
                        <div className="row justify-content-center mb-2 mt-1">
                            <div className="col-md-6">
                                {outlets.map((outlet, index) => (
                                    <div 
                                        key={index} 
                                        className="card mb-2" 
                                        onClick={() => handleCardClick(outlet)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="card-body position-relative p-0" style={{ backgroundColor: '#EFEFEF' }}>
                                            <div className="position-absolute top-0 end-0 m-0 p-0">
                                                <div className="star-container p-0 m-0">
                                                    <span 
                                                        className="star-text m-0 p-1" 
                                                        style={{ backgroundColor: '#DC3545', fontSize: '11px', color: 'white', borderRadius: '0px 3px 0px 0px', border: '3px solid #DC3545' }}
                                                    >
                                                        {Math.abs(outlet.distance).toLocaleString()} m
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="p-3" style={{ fontSize: '12px' }}>
                                                <Table className='mb-0'>
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0'}} >
                                                                Profil Outlet
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Outlet Id</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.OUTLET_ID}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Nama Outlet</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.NAMA_OUTLET}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>TIPE</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.fisik}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Area Name</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.AREA_NAME}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Regional</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.REGIONAL}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Branch</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.BRANCH}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Cluster</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.CLUSTER_NAME}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>City</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KOTA}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Kecamatan</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KECAMATAN}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Kelurahan</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.KELURAHAN}</td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'15px 7px 0 7px', border: '0'}}> 
                                                                Performance Bulan ini
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Transaction</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.trx_now).toLocaleString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Revenue</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.rev_now).toLocaleString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Star Point</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.star_now).toLocaleString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Dikunjungi</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.total_call_now).toLocaleString()} Kali</td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={2} style={{fontWeight: 'bold', backgroundColor:'#EFEFEF', padding:'15px 7px 0 7px', border: '0'}}> 
                                                                Performance Tahun ini
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Transaction</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.trx_total).toLocaleString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Revenue</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.rev_total).toLocaleString()}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Star Point</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {Math.abs(outlet.star_total).toLocaleString()}</td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Total Visit</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.total_call_total}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>Last Visited</td>
                                                            <td style={{ backgroundColor:'#EFEFEF', padding:'0px 7px 0 7px', border: '0' }}>: {outlet.tgl_max}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className="position-absolute top-50 end-0 translate-middle-y px-3">
                                                <FaAngleRight size={22} style={{ color: '#333333' }} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                ))}
                            </div>
                        </div>
                    </>
                    )
                )}
                <br/><br/><br/><br/>
            </div>
        </div>
    );
}

export default PilihOutlet;
