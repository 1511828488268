import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CreateHboUserBranch = ({ username, areaflag, areaname, regional, branch }) => {
    const [usernameGenerated, setUsernameGenerated] = useState('');
    const [namaLengkap, setNamaLengkap] = useState('');
    const [tipeUser, setTipeUser] = useState('');
    const [clusterName, setClusterName] = useState('');
    const [message, setMessage] = useState('');
    const [clusters, setClusters] = useState([]);

    useEffect(() => {
        generateAndSetQuestion0(); // Generate and set question0 on component mount
    }, []);

    const generateAndSetQuestion0 = () => {
        const newRandomString = generateRandomString();
        setUsernameGenerated(newRandomString);
    };

    useEffect(() => {
        if (branch) {
            handleBranchChange(branch);
        }
    }, [branch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER}/submit-user-hbo-branch`, {
            username,
            usernameGenerated,
            namaLengkap,
            tipeUser,
            areaflag,
            areaname,
            regional,
            branch,
            clusterName,
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
                setNamaLengkap('');
                setTipeUser('');
                setClusterName('');
                generateAndSetQuestion0();
            } else {
                setMessage(response.data.message); 
                setNamaLengkap('');
                setTipeUser('');
                setClusterName('');
            }
        }).catch((error) => {
            setMessage('Failed due to an error.');
            setNamaLengkap('');
            setTipeUser('');
            setClusterName('');
        });
    };

    
    const handleBranchChange = (branchnya) => {
        let clustersOptions = [];

        switch (branchnya) {
            case 'BALIKPAPAN': clustersOptions = ['KUTAI','BALIKPAPAN','KOTA BALIKPAPAN']; break;
            case 'BANJARMASIN': clustersOptions = ['HULU SUNGAI','KOTABARU','BANJAR']; break;
            case 'BATAM': clustersOptions = ['TANJUNG PINANG','BATAM','GREATER BATAM']; break;
            case 'BENGKULU': clustersOptions = ['KOTA BENGKULU','MUSI RAWAS','BENGKULU']; break;
            case 'BOGOR': clustersOptions = ['SUKABUMI','BOGOR']; break;
            case 'BONE': clustersOptions = ['BULUKUMBA','WAJO SOPENG','BONE']; break;
            case 'CIREBON': clustersOptions = ['SUBANG INDRAMAYU','CIREBON RAYA']; break;
            case 'DENPASAR': clustersOptions = ['BALI BARAT','BALI TIMUR','BALI TENGAH']; break;
            case 'JAMBI': clustersOptions = ['KERINCI MERANGIN','MUARO DAN KOTA JAMBI','TEBO BUNGO','TANJUNG JABUNG','SAROLANGUN']; break;
            case 'JAYAPURA': clustersOptions = ['PUNCAK JAYAWIJAYA','KOTA JAYAPURA','BIAK NUMFOR','KEEROM','SENTANI']; break;
            case 'JEMBER': clustersOptions = ['JEMBER','SITUBONDOWOSO','KOTA PROBOLINGGO','BANYUWANGI']; break;
            case 'LAMONGAN': clustersOptions = ['LAMONGAN GRESIK','TUBAN BOJONEGORO']; break;
            case 'MALANG': clustersOptions = ['MALANG','TULUNGAGUNG']; break;
            case 'MATARAM': clustersOptions = ['SUMBAWA TIMUR','SUMBAWA BARAT','LOMBOK']; break;
            case 'MEDAN': clustersOptions = ['KOTA MEDAN','MEDAN','SERDANG']; break;
            case 'PANGKAL PINANG': clustersOptions = ['BANGKA SELATAN','BANGKA UTARA']; break;
            case 'PARE-PARE': clustersOptions = ['MAMUJU MAJENE','LUWU PALOPO','TORAJA','PARE SIDRAP']; break;
            case 'PEKANBARU': clustersOptions = ['INDRAGIRI HILIR','PELALAWAN','SIAK MERANTI','INDRAGIRI HULU','PEKANBARU','GREATER PEKANBARU']; break;
            case 'PURWOKERTO': clustersOptions = ['CILACAP BANYUMAS','WONOSOBO BANJARNEGARA']; break;
            case 'RANTAU PRAPAT': clustersOptions = ['ASAHAN','PADANG LAWAS','LABUHAN BATU']; break;
            case 'SERANG': clustersOptions = ['LEBAK PANDEGLANG','SERANG CILEGON','KAB TANGERANG']; break;
            case 'SIDOARJO': clustersOptions = ['SIDOARJO PASURUAN','JOMBANG MOJOKERTO']; break;
            case 'SOREANG': clustersOptions = ['BANDUNG','SUMEDANG MAJALENGKA']; break;
            case 'SORONG': clustersOptions = ['MANOKWARI','CENDRAWASIH','NABIRE','SORONG RAJA AMPAT']; break;
            case 'SOUTHERN JAKARTA': clustersOptions = ['JAKPUSEL','JAKTIM','JAKTIM 1']; break;
            case 'SURABAYA': clustersOptions = ['MADURA','KOTA SURABAYA']; break;
            case 'TANGERANG': clustersOptions = ['KOTA TANGERANG','TANGSEL']; break;
            case 'TASIKMALAYA': clustersOptions = ['CIAMIS','GARUT','TASIKMALAYA']; break;
            case 'YOGYAKARTA': clustersOptions = ['YOGYAKARTA']; break;
            case 'AMBON': clustersOptions = ['KOTA AMBON','SERAM TIMUR MALUKU','KEPULAUAN TUAL','SERAM BARAT BURU']; break;
            case 'BANDA ACEH': clustersOptions = ['ACEH BARAT','GAYO','SABANG ACEH','PIDIE BIREUN','ACEH SELATAN']; break;
            case 'BANDUNG': clustersOptions = ['CIANJUR','KOTA BANDUNG']; break;
            case 'BEKASI': clustersOptions = ['DEPOK','KOTA BEKASI']; break;
            case 'BINJAI': clustersOptions = ['LANGSA','LANGKAT BINJAI','LHOKSEUMAWE']; break;
            case 'BUKIT TINGGI': clustersOptions = ['AGAM','PAYAKUMBUH','PASAMAN']; break;
            case 'DUMAI': clustersOptions = ['ROKAN HILIR','ROKAN HULU','DUMAI BENGKALIS','KAMPAR','KUANTAN']; break;
            case 'FLORES': clustersOptions = ['MANGGARAI','FLORES TIMUR','ENDE SIKKA']; break;
            case 'KARAWANG': clustersOptions = ['KARAWANG PURWAKARTA','BEKASI']; break;
            case 'KENDARI': clustersOptions = ['WAKATOBI','KENDARI','KOLAKA']; break;
            case 'KUPANG': clustersOptions = ['MALAKA TIMTIM BELU','SUMBA','KUPANG ROTE']; break;
            case 'LAMPUNG': clustersOptions = ['PRINGSEWU LAMPUNG TIMUR','LAMPUNG BARAT','KOTA BANDAR LAMPUNG','LAMPUNG METRO','TULANG BAWANG','WAY KANAN']; break;
            case 'MADIUN': clustersOptions = ['MADIUN','PONOROGO','KEDIRI']; break;
            case 'MAGELANG': clustersOptions = ['MAGELANG TEMANGGUNG','KEBUMEN']; break;
            case 'MAKASSAR': clustersOptions = ['BARRU MAROS','GOWA','KOTA MAKASSAR']; break;
            case 'MANADO': clustersOptions = ['MANADO TALAUD','GORONTALO','BOLAANG MONGONDOW','BITUNG MINAHASA','PAHUWATO']; break;
            case 'NORTHERN JAKARTA': clustersOptions = ['JAKUT','JAKBAR','NEW JAKUT']; break;
            case 'PADANG': clustersOptions = ['KOTA PADANG','SOLOK SAWAH LUNTO','SIJUNJUNG DHARMASRAYA','MENTAWAI DAN PESISIR','PADANG PARIAMAN']; break;
            case 'PADANG SIDEMPUAN': clustersOptions = ['TAPANULI','NIAS','SIDEMPUAN']; break;
            case 'PALANGKARAYA': clustersOptions = ['PALANGKARAYA','BARITO']; break;
            case 'PALEMBANG': clustersOptions = ['BANYUASIN','OGAN KOMERING ILIR','KOTA PALEMBANG','MUSI BANYUASIN','PRABUMULIH','OGAN KOMERING ULU']; break;
            case 'PALU': clustersOptions = ['BANGGAI','MOROWALI','PALU DONGGALA','POSO SIGI','PARIGI TOLI']; break;
            case 'PANGKALAN BUN': clustersOptions = ['KOTAWARINGIN','KETAPANG']; break;
            case 'PEKALONGAN': clustersOptions = ['BATANG','TEGAL BREBES','PEMALANG PURBA']; break;
            case 'PEMATANG SIANTAR': clustersOptions = ['SIMALUNGUN SIANTAR','KARO','SAMOSIR','SERDANG BEDAGAI']; break;
            case 'PONTIANAK': clustersOptions = ['SAMBAS','PONTIANAK','SINTANG']; break;
            case 'SAMARINDA': clustersOptions = ['BONTANG','KOTA SAMARINDA']; break;
            case 'SEMARANG': clustersOptions = ['SEMARANG','DEMAK','JEPARA KUDUS','PATI']; break;
            case 'SURAKARTA': clustersOptions = ['BOYOLALI','SURAKARTA','SRAGEN']; break;
            case 'TARAKAN': clustersOptions = ['BERAU','TARAKAN']; break;
            case 'TERNATE': clustersOptions = ['HALMAHERA MOROTAI','HALMAHERA TIDORE','TERNATE']; break;
            case 'TIMIKA': clustersOptions = ['MIMIKA','MERAUKE']; break;                                                                                                                   
            default:
                clustersOptions = [];
                break;
        }

        setClusters(clustersOptions);
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Create User HBO / TIP</p>
                    </div>
                </div>
            </div>
                    
            <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-2 mb-2">                    
                        <form onSubmit={handleSubmit}>                                                         
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-2 mb-0">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Code Generated</label></p>
                                                <div>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        value={usernameGenerated}
                                                        onChange={(e) => setUsernameGenerated(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row justify-content-center">
                                <div className="col-md-12 pt-2 mb-3">    
                                    <div className="card">            
                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1 fw-bold'>AREA NAME</label>
                                                <div>{areaflag} ({areaname})</div>
                                            </div>
                                        
                                            <div className="mt-3 mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1 fw-bold'>REGIONAL</label>
                                                <div>{regional}</div>
                                            </div>
                                        
                                            <div className="mt-3 mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1 fw-bold'>BRANCH</label>
                                                <div>{branch}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-1">
                                    <div className="card">
                                        <div className="card-body pb-1">
                                            <div style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Nama Lengkap</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={namaLengkap} 
                                                        onChange={(e) => setNamaLengkap(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="card-body pb-1">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <label className='mb-1'>Tipe User</label>
                                                <div>
                                                    <select 
                                                        required
                                                        value={tipeUser} 
                                                        onChange={(e) => setTipeUser(e.target.value)} 
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Pilih Tipe User</option>
                                                        <option value="H">HBO User</option>
                                                        <option value="T">TIP User</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        {tipeUser === 'H' && (
                                            <div className="card-body pb-3">
                                                <div className="mb-1" style={{fontSize: '13px'}}>
                                                    <label className='mb-1'>Cluster</label>
                                                    <div>
                                                        <select
                                                            required 
                                                            value={clusterName}  
                                                            onChange={(e) => setClusterName(e.target.value)} 
                                                            className="form-select"
                                                            style={{fontSize: '13px'}}
                                                        >
                                                            <option value="">Select Cluster</option>
                                                            {clusters.map((cluster, index) => (
                                                                <option key={index} value={cluster}>{cluster}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="card m-3">
                                            <button type="submit" className="btn btn-danger p-2">Tambah User</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                        {message && <p className="mt-3 text-center">{message}</p>}
                        <br/><br/><br/><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

// Fungsi untuk menghasilkan string acak dengan pola 3 huruf dan 3 angka
function generateRandomString() {
    const letters = 'ABCDEFGHJKLMNPQRSTUWXYZ';
    const numbers = '23456789';
    let result = '';
    
    for (let i = 0; i < 2; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    for (let i = 0; i < 3; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return result;
}

export default CreateHboUserBranch;
