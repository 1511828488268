import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaHandsHelping, FaHouseUser, FaRegNewspaper, FaSignOutAlt } from 'react-icons/fa';
import './css/BottomNavigationBar.css';

const BottomNavigationBar = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post(`${process.env.REACT_APP_SERVER}/auth/logout`, {}, { withCredentials: true })
      .then(() => {
        onLogout(); // Callback untuk mengupdate state setelah logout
        navigate('/'); // Navigasi ke halaman login
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <Navbar fixed="bottom" className="justify-content-center" style={{ background: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}>
      <Nav className="w-100 px-4 d-flex justify-content-between align-items-center">
        <Nav.Item className="nav-icon text-center">
          <Nav.Link as={NavLink} to="/">
            <FaHouseUser size={17} />
            <div style={{ fontSize: '9px' }}>Home</div>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="nav-icon text-center" style={{ paddingRight: '0px' }}>
          <Nav.Link as={NavLink} to="/news">
            <FaRegNewspaper size={17} />
            <div style={{ fontSize: '9px' }}>Inbox</div>
          </Nav.Link>
        </Nav.Item>

        { 
        /*
        <Button className="circle-button" style={{ background: 'linear-gradient(to right, #8F0B3D, #DC3545)', border: '5px solid white' }}>
          <NavLink to="/dashboard">
            <FaCalendarCheck size={17} style={{ color: 'white' }} />
          </NavLink>
        </Button>
        */
        }

        <Nav.Item className="nav-icon text-center">
          <Nav.Link as={NavLink} to="/help">
            <FaHandsHelping size={17} />
            <div style={{ fontSize: '9px' }}>Bantuan</div>
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="nav-icon text-center">
          <Nav.Link onClick={handleLogout}>
            <FaSignOutAlt size={18} />
            <div style={{ fontSize: '9px' }}>Logout</div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

export default BottomNavigationBar;
