import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; // Instal library date-picker seperti 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS untuk date picker
import Table from 'react-bootstrap/Table';

const DjpSchedule = ({ username }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [outlets, setOutlets] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedDate) {
            alert('Please select a date.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/djp-outlet-date`, {
                params: {
                    username,
                    tanggal: selectedDate.toISOString().split('T')[0] // Convert date to YYYY-MM-DD format
                }
            });
            setOutlets(response.data.data); // Update to match the backend response format
        } catch (error) {
            console.error('Error fetching outlets:', error);
            alert('Failed to fetch outlets. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4" style={{ backgroundImage: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Search Outlet DJP</p>
                    </div>
                </div>
            </div>
            <div className="container mt-0 px-2" style={{ borderRadius: '0px 0px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-1">
                        <Container>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mt-4">
                                    <Col xs={8} md={8} sm={8} >
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control py-1"
                                            placeholderText="Select a date"
                                        />
                                    </Col>
                                    <Col xs={4} md={4} sm={4}>
                                        <Button variant="danger" type="submit" className="mt-0 mx-0 py-1">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            {loading ? (
                                <p className="mt-4">Loading...</p>
                            ) : (
                                <Row className="mt-4">
                                    <Col>
                                        <Table striped bordered hover style={{ fontSize: '12px' }}>
                                            <thead>
                                                <tr className="text-center">
                                                    <th>NO</th>
                                                    <th>OUTLET ID</th>
                                                    <th>CHECK IN</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(outlets) && outlets.length > 0 ? (
                                                    outlets.map((outlet, index) => (
                                                        <tr key={index} className="p-0">
                                                            <td className="text-center p-1">{index + 1}</td>
                                                            <td className="text-center p-1">{outlet.outlet_id}</td>
                                                            <td className="text-left p-1">{outlet.status}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">No data found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            )}
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DjpSchedule;
