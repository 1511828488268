import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Circle, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './css/Lokasi.css';
import { FaSyncAlt } from "react-icons/fa";

// Icon for the user's location marker
const userIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const ResetMapView = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
        if (map) {
            map.setView(center, zoom);
        }
    }, [map, center, zoom]);
    return null;
};

const Lokasi = ({ username }) => {
    const [position, setPosition] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [mapCenter, setMapCenter] = useState([0, 0]); // Default center
    const [mapZoom, setMapZoom] = useState(17); // Default zoom level
    const [refreshing, setRefreshing] = useState(false); // Track if refreshing

    const fetchNearbyOutlets = useCallback(async (latitude, longitude) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/data/nearby-outlets-map`, {
                params: {
                    latitude,
                    longitude,
                    uname: username
                }
            });
            const nearbyOutlets = response.data;
            const nearbyMarkers = nearbyOutlets.map(outlet => ({
                position: [parseFloat(outlet.LATITUDE), parseFloat(outlet.LONGITUDE)],
                name: outlet.NAMA_OUTLET
            }));
            setMarkers(nearbyMarkers);
        } catch (error) {
            console.error('Error fetching nearby outlets:', error);
        }
    }, [username]);

    const updatePosition = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    const { latitude, longitude } = pos.coords;
                    const currentPosition = [latitude, longitude];
                    setPosition(currentPosition);
                    setMapCenter(currentPosition); // Reset map center
                    setMapZoom(17); // Reset zoom level
                    setMarkers([]); // Clear existing markers

                    // Fetch new outlets
                    fetchNearbyOutlets(latitude, longitude);

                    // Set refreshing state to false after a delay
                    setTimeout(() => setRefreshing(false), 300);
                },
                (err) => {
                    console.error(err);
                    alert('Error getting geolocation');
                    setRefreshing(false); // Reset refreshing state on error
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
            setRefreshing(false); // Reset refreshing state if geolocation is not supported
        }
    }, [fetchNearbyOutlets]);

    useEffect(() => {
        updatePosition(); // Initial load
    }, [updatePosition]);

    return (
        <div style={{ position: 'relative' }} className="p-0 m-0">
            {position ? (
                <>
                    <MapContainer center={mapCenter} zoom={mapZoom} style={{ height: '410px', width: '100%' }} attributionControl={false}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position} icon={userIcon}>
                            {/* Tooltip or Popup for user location */}
                        </Marker>
                        <Circle
                            center={position}
                            radius={50}
                            color="#C82333"
                            fillColor="#DC3545"
                            fillOpacity={0.5}
                            className="animated-circle"
                        />
                        {markers.map((marker, index) => (
                            <Circle
                                key={index}
                                center={marker.position}
                                radius={10}
                                color="red"
                                eventHandlers={{
                                    click: () => {
                                        setActiveMarker(index);
                                    },
                                }}
                            >
                                {activeMarker === index && (
                                    <Tooltip direction="top">
                                        <span>{marker.name}</span>
                                    </Tooltip>
                                )}
                            </Circle>
                        ))}
                        <ResetMapView center={mapCenter} zoom={mapZoom} />
                    </MapContainer>
                    <button 
                        onClick={() => {
                            setRefreshing(true); // Set refreshing state to true
                            updatePosition(); 
                        }} 
                        style={{ 
                            position: 'absolute', 
                            bottom: '10px', 
                            left: '50%', 
                            transform: 'translateX(-50%)',
                            padding: '10px 20px',
                            fontSize: '14px',
                            backgroundColor: refreshing ? '#C82333' : '#DC3545', // Change color when refreshing
                            color: '#fff',
                            border: 'none',
                            borderRadius: '25px',
                            cursor: 'pointer',
                            zIndex: 1000, // Ensure button is above other elements
                            transition: 'background-color 0.3s', // Smooth color transition
                            boxShadow: refreshing ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none' // Add shadow effect when refreshing
                        }}
                    >
                        {refreshing ? 'Refreshing...' : <><FaSyncAlt /> &nbsp;Refresh Lokasi</>}
                    </button>
                </>
            ) : (
                <p>Loading map...</p>
            )}
        </div>
    );
};

export default Lokasi;
