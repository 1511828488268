import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ChangePassword = ({ setCurrentUsername }) => {
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [message, setMessage] = useState('');
    const { username, flagnya } = useParams();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (question1 !== question2) {
            setMessage('Password tidak sesuai.');
            setQuestion1('');
            setQuestion2('');
            return;
        } else{
            if (question1.length < 5) {
                setMessage('Password kurang dari 5 karakter.');
                setQuestion1('');
                setQuestion2('');
                return;
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER}/auth/submit-change-password`, {
            username,
            question1,
            question2,
            flagnya       
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
                setQuestion1('');
                setQuestion2('');
            } else {
                setMessage(response.data.message); 
                setQuestion1('');
                setQuestion2('');
            }
        }).catch((error) => {
            setMessage('Failed to change password due to an error.');
            setQuestion1('');
            setQuestion2('');
        });

        
    };
    
  return (
    <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
        <div className="container-fluid mt-0 pt-3 pb-4 px-4">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <p className="card-title mt-0 mb-0 text-white">Ganti Password</p>
                </div>
            </div>
        </div>
                
        <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
            <div className="row justify-content-center">
                <div className="col-md-12 pt-5 mb-5">
                    <p className="bg-secondary p-3" style={{ color:'#FFFFFF', borderRadius: '5px', fontSize:'13px'}}>
                        Jika anda masih menggunakan password default, silahkan ganti password untuk keamanan
                    </p>
                    <form onSubmit={handleSubmit}>                                                         
                        <div className="row justify-content-start">
                            <div className="col-md-12 pb-2">
                                <div className="card px-3 py-1">
                                    Username : {username}
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-12 pb-2">
                                <div className="card">
                                    <div className="card-body pb-3">
                                        <div className="mb-1" style={{fontSize: '13px'}}>
                                            <p><label>Password Baru</label></p>
                                            <div>
                                                <input
                                                    type="password" 
                                                    value={question1} 
                                                    onChange={(e) => setQuestion1(e.target.value)} 
                                                    className="form-control" 
                                                    style={{fontSize: '13px'}}
                                                />
                                            </div>
                                        </div>
                                    
                                        <div className="mb-1 mt-3" style={{fontSize: '13px'}}>
                                            <p><label>Ulangi Password Baru</label></p>
                                            <div>
                                                <input
                                                    type="password" 
                                                    value={question2} 
                                                    onChange={(e) => setQuestion2(e.target.value)} 
                                                    className="form-control" 
                                                    style={{fontSize: '13px'}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-12">
                                <div className="card">
                                    <button type="submit" className="btn btn-danger">Ganti Password</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {message && <p className="mt-3 text-center">{message}</p>}
                    <br/><br/><br/><br/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChangePassword
