import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaArrowCircleLeft, FaArrowCircleRight , FaAngleRight } from 'react-icons/fa';

const HistorySurvey = ({ username, onLogout }) => {
  const navigate = useNavigate();
  const [datane, setDatane] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER}/data/show-history`, { params: { usr: username, page } })
      .then(response => {
        setDatane(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Profile:', error);
        setLoading(false);
      });
  }, [username, page]);
  
  const handleCardClick = (datane) => {
    navigate(`/history-detail/${datane.outlet_id}`);
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #8F0B3D, #DC3545)' }}>
      <div className="container-fluid mt-0 pt-3 pb-4 px-4">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <p className="card-title mt-0 mb-0 text-white">History Survey Tahun ini</p>
          </div>
        </div>
      </div>
      <div className="container mt-0 px-2 bg-light" style={{ borderRadius: '0px 0px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
        <div className="row justify-content-center">
          <div className="col-md-12 pt-5">
            {loading ? (
              <p>Loading Tunggu Sebentar...</p>
            ) : (
              <>
                <div className="row justify-content-center mb-2 mt-1">
                  <div className="col-md-12">
                    {datane.map((usernya, index) => (
                      <div 
                        key={index} 
                        className="card mb-2" 
                        onClick={() => handleCardClick(usernya)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className="card-body position-relative p-0 m-0 shadow-sm">
                          <div className="p-2 mt-0">
                            <Table className='mb-0' style={{ fontSize: '11px', borderRadius: '10px'}}>
                              <tbody>
                                <tr>
                                  <td colSpan={2} className="fw-bold mb-0 px-2 py-1"> 
                                    { usernya.outlet_id } - { usernya.NAMA_OUTLET }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width:'20%', border:'0'}} className="px-2 p-0 pt-1">Regional</td>
                                  <td style={{border:'0'}} className="px-2 p-0 pt-1">: {usernya.REGIONAL}</td>
                                </tr>
                                <tr>
                                  <td style={{border:'0'}} className="px-2 p-0">Kota</td>
                                  <td style={{border:'0'}} className="px-2 p-0">: {usernya.KOTA}</td>
                                </tr>
                                <tr>
                                  <td style={{border:'0'}} className="px-2 p-0">Feedback</td>
                                  <td style={{border:'0'}} className="px-2 p-0">: {usernya.ket_feedback}</td>
                                </tr>
                                <tr>
                                  <td style={{border:'0'}} className="px-2 p-0">Visit Date</td>
                                  <td style={{border:'0'}} className="px-2 p-0">: {usernya.tgl_visit} 
                                    <span style={{ float: 'right', color:'#999', fontSize: '10px'}}>{usernya.harinya}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="position-absolute top-50 end-0 translate-middle-y px-3">
                            <FaAngleRight size={22} style={{ color: '#333333' }} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {page > 1 && <FaArrowCircleLeft onClick={handlePrevious} size={35} className='mx-1'/>}
                  <span>Page {page} of {totalPages}</span>
                  {page < totalPages && <FaArrowCircleRight onClick={handleNext} size={35} className='mx-1'/>}
                </div>
              </>
            )}
            <br/><br/><br/><br/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistorySurvey;
