import React from 'react'

const ReportSurvey = () => {
  return (
    <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
      <div className="container-fluid mt-0 pt-3 pb-4 px-4">
          <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                  <p className="card-title mt-0 mb-0 text-white">Report Survey</p>
              </div>
          </div>
      </div>
      <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
        <div className="row justify-content-center">
          <div className="col-md-12 pt-5">

          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportSurvey
