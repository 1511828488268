import React, { useState } from 'react';
import axios from 'axios';

const CreateInternalUser = ({ username }) => {
    const [question0, setQuestion0] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [message, setMessage] = useState('');
    const [regionals, setRegionals] = useState([]);


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_SERVER}/submit-user-internal`, {
            username,
            question0,
            question1,
            question2,
            question3,
            question4
        }, { withCredentials: true }).then((response) => {
            if (response.data.success) {
                setMessage(response.data.message); 
                setQuestion1('');
                setQuestion2('');
                setQuestion3('');
                setQuestion4('');
            } else {
                setMessage(response.data.message); 
                setQuestion1('');
                setQuestion2('');
                setQuestion3('');
                setQuestion4('');
            }
        }).catch((error) => {
            setMessage('Failed due to an error.');
            setQuestion1('');
            setQuestion2('');
            setQuestion3('');
            setQuestion4('');
        });
    };

    const handleAreaChange = (e) => {
        const area = e.target.value;
        setQuestion2(area);
        let regionalsOptions = [];

        switch (area) {
            case 'AREA 1':
                regionalsOptions = ['SUMBAGSEL', 'SUMBAGTENG', 'SUMBAGUT'];
                break;
            case 'AREA 2':
                regionalsOptions = ['CENTRAL JABOTABEK', 'EASTERN JABOTABEK', 'JABAR', 'WESTERN JABOTABEK'];
                break;
            case 'AREA 3':
                regionalsOptions = ['JATENG-DIY', 'JATIM', 'BALI NUSRA'];
                break;
            case 'AREA 4':
                regionalsOptions = ['KALIMANTAN', 'SULAWESI', 'MALUKU DAN PAPUA'];
                break;
            default:
                regionalsOptions = [];
                break;
        }

        setRegionals(regionalsOptions);
        setQuestion3(''); // Reset the regional selection when area changes
    };

    return (
        <div className="overflow-auto" style={{ maxHeight: '100vh', backgroundImage: 'linear-gradient(to right, #9F4C51, #DC3545)' }}>
            <div className="container-fluid mt-0 pt-3 pb-4 px-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <p className="card-title mt-0 mb-0 text-white">Create User Internal</p>
                    </div>
                </div>
            </div>
                    
            <div className="container mt-0 px-4 bg-light" style={{ borderRadius: '40px 40px 0 0', maxHeight: '80vh', overflowY: 'auto' }}>
                <div className="row justify-content-center">
                    <div className="col-md-12 pt-5 mb-5">                    
                        <form onSubmit={handleSubmit}>                                                         
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-2">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Username</label></p>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={question0}
                                                        onChange={(e) => setQuestion0(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12 pb-2">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Nama Lengkap </label></p>
                                                <div>
                                                    <input
                                                        type="text"
                                                        required
                                                        value={question1} 
                                                        onChange={(e) => setQuestion1(e.target.value)} 
                                                        className="form-control" 
                                                        style={{fontSize: '13px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row justify-content-center mb-3">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body mb-0">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Area Name</label></p>
                                                <div>
                                                    <select 
                                                        required
                                                        value={question2} 
                                                        onChange={handleAreaChange} 
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Select Area</option>
                                                        <option value="AREA 1">Area 1 / Sumatera</option>
                                                        <option value="AREA 2">Area 2 / Jabotabek & Jabar</option>
                                                        <option value="AREA 3">Area 3 / Jawa - Bali - Nusra</option>
                                                        <option value="AREA 4">Area 4 / Pamasuka</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-3">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body mb-0">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Regional</label></p>
                                                <div>
                                                    <select
                                                        required 
                                                        value={question3} 
                                                        onChange={(e) => setQuestion3(e.target.value)} 
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Select Regional</option>
                                                        {regionals.map((regional, index) => (
                                                            <option key={index} value={regional}>{regional}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-3">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body mb-0">
                                            <div className="mb-1" style={{fontSize: '13px'}}>
                                                <p><label>Level</label></p>
                                                <div>
                                                    <select 
                                                        required
                                                        value={question4}
                                                        onChange={(e) => setQuestion4(e.target.value)} 
                                                        className="form-select"
                                                        style={{fontSize: '13px'}}
                                                    >
                                                        <option value="">Pilih Level</option>
                                                        <option value="0">Level 0</option>
                                                        <option value="1">Level 1</option>
                                                        <option value="2">Level 2</option>
                                                        <option value="3">Level 3</option>
                                                        <option value="5">Level 5</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-5">
                                <div className="col-md-12">
                                    <div className="card">
                                        <button type="submit" className="btn btn-danger p-2">Tambah User</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {message && <p className="mt-3 text-center">{message}</p>}
                        <br/><br/><br/><br/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CreateInternalUser;
